import styled from 'styled-components';
import Prep from '../Prep';

const Product = () => {
    return (
        <div style={{width: "100%", height: "100%", padding: "8px 24px 24px 24px"}}>
            <Prep />
        </div>
    )
}

export default Product