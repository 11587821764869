import { forwardRef, useEffect, useState } from 'react';
import anlStyle from '../../style/analytics.module.css';
import unitStyle from '../../style/units.module.css';
import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import '../../style/datePicker.css';

registerLocale('ko', ko)

export const Calendar = (props) => {
    const {type, displayedDate, setDisplayedDate, displayedRange, setDisplayedRange, startDate, endDate, dateRange, updateDateRange} = props;

    const [openCalendar, setOpenCalendar] = useState(false);
    const [isSelectionOpen, setIsSelectionOpen] = useState(false);

    const wrapStyle = (type === 'segment') ? anlStyle.Calendar__Wrap : anlStyle.Calendar__WrapAbs;
    const selectionStyle = (type === 'segment') ? anlStyle.Calendar__Selection : anlStyle.Calendar__SelectionAbs;

    const handleClickDate = (e) => {
        e.stopPropagation();
        setDisplayedDate(e.currentTarget.dataset.value);
        setIsSelectionOpen(false);
        if (e.currentTarget.dataset.start) updateDateRange([new Date(e.currentTarget.dataset.start), new Date(e.currentTarget.dataset.start)]);
        if (e.currentTarget.dataset.end) updateDateRange([new Date(e.currentTarget.dataset.start), new Date(e.currentTarget.dataset.end)]);
    }

    const handleCustomDate = (e) => {
        e.stopPropagation();
        setOpenCalendar(true);
    }

    return (
        <>
            <div className={wrapStyle} onClick={() => setIsSelectionOpen(!isSelectionOpen)}>
                <img src='/img/analytics/analytics-calendar.png' width={24} height={24} />
                <span className='h6 fc-gray-600'>{displayedDate}</span>
                <label htmlFor='datepicker' >
                    <img src='/img/analytics/analytics-expand.png' width={24} height={24} className='pointer' />
                </label>
            </div>
            {
                isSelectionOpen &&
                <div className={selectionStyle}>
                    <Selection 
                        type={type}
                        setDisplayedDate={setDisplayedDate} 
                        handleClickDate={handleClickDate}
                        handleCustomDate={handleCustomDate}
                    />
                    {
                        openCalendar &&
                        <div className={anlStyle.Calendar__DatePicker__Wrap}>
                            <CalendarRange 
                                setDisplayedDate={setDisplayedDate}
                                displayedRange={displayedRange}
                                setDisplayedRange={setDisplayedRange}
                                startDate={startDate}
                                endDate={endDate}
                                dateRange={dateRange}
                                updateDateRange={updateDateRange}    
                            />
                            <hr />
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <div
                                    className={`${unitStyle.Button__cancel} pointer`}
                                    style={{width: '92px', height: '42px'}}
                                    onClick={() => {
                                        setOpenCalendar(false)
                                        setIsSelectionOpen(false)
                                    }}
                                >
                                    <p className='h6 fc-prim-800'>취소하기</p>
                                </div>
                                <div className='spacing-16' />
                                <div
                                    className={`${unitStyle.Button__primary} pointer`}
                                    style={{width: '92px', height: '42px'}}
                                    onClick={() => {
                                        setOpenCalendar(false)
                                        setIsSelectionOpen(false)
                                    }}
                                >
                                    <p className='h6 fc-white'>적용하기</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

const Selection = (props) => {
    const { type, handleClickDate, handleCustomDate } = props;
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    let past3 = new Date();
    past3.setDate(today.getDate() - 3);
    let past7 = new Date();
    past7.setDate(today.getDate() - 7);
    let past14 = new Date();
    past14.setDate(today.getDate() - 14);
    let past30 = new Date(); 
    past30.setDate(today.getDate() - 30);
    let monthStart = new Date();
    monthStart.setDate(1);
    let startDay = new Date('2024-02-21');
    
    const formatDate = (m, d, y) => {
        return `${m+1}월 ${d}일, ${y}`;
    }
    
    let formattedToday = formatDate(today.getMonth(), today.getDate(), today.getFullYear());
    let formattedYesterday = formatDate(yesterday.getMonth(), yesterday.getDate(), yesterday.getFullYear());
    let formattedPast3 = formatDate(past3.getMonth(), past3.getDate(), past3.getFullYear());
    let formattedPast7 = formatDate(past7.getMonth(), past7.getDate(), past7.getFullYear());
    let formattedPast14 = formatDate(past14.getMonth(), past14.getDate(), past14.getFullYear());
    let formattedPast30 = formatDate(past30.getMonth(), past30.getDate(), past30.getFullYear());
    let formattedThisMonth = formatDate(today.getMonth(), 1, today.getFullYear());
    let formattedStartDay = formatDate(startDay.getMonth(), startDay.getDate(), startDay.getFullYear());

    if (type === 'analytics') {
        return (
            <div style={{width: '345px', borderRight: '1px solid #eee'}}>
                <div 
                    className={anlStyle.Calendar__Selection__List} 
                    data-value={formattedToday} 
                    data-start={today}
                    onClick={(e) => handleClickDate(e)}
                >
                    <p className='h8'>오늘</p>
                    <p className='h10'>{formattedToday}</p>
                </div>
                <div 
                    className={anlStyle.Calendar__Selection__List} 
                    data-value={formattedYesterday} 
                    data-start={yesterday}
                    onClick={(e) => handleClickDate(e)}
                >
                    <p className='h8'>어제</p>
                    <p className='h10'>{formattedYesterday}</p>
                </div>
                <div 
                    className={anlStyle.Calendar__Selection__List} 
                    data-value={`${formattedPast7}~${formattedToday}`} 
                    data-start={past7}
                    data-end={today}
                    onClick={(e) => handleClickDate(e)}
                >
                    <p className='h8'>지난 7일</p>
                    <p className='h10'>{formattedPast7} - {formattedToday}</p>
                </div>
                <div 
                    className={anlStyle.Calendar__Selection__List} 
                    data-value={`${formattedPast30}~${formattedToday}`} 
                    data-start={past30}
                    data-end={today}
                    onClick={(e) => handleClickDate(e)}
                >
                    <p className='h8'>지난 30일</p>
                    <p className='h10'>{formattedPast30} - {formattedToday}</p>
                </div>
                <div 
                    className={anlStyle.Calendar__Selection__List} 
                    data-value={`${formattedThisMonth}~${formattedToday}`} 
                    data-start={monthStart}
                    data-end={today}
                    onClick={(e) => handleClickDate(e)}
                >
                    <p className='h8'>이번 달</p>
                    <p className='h10'>{formattedThisMonth} - {formattedToday}</p>
                </div>
                <div 
                    className={anlStyle.Calendar__Selection__List} 
                    data-value={`${formattedStartDay}~${formattedToday}`} 
                    data-start={startDay}
                    data-end={today}
                    onClick={(e) => handleClickDate(e)}
                >
                    <p className='h8'>모두</p>
                    <p className='h10'>{formattedStartDay} - {formattedToday}</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedToday}~`} onClick={(e) => handleCustomDate(e)}>
                    <p className='h8'>기간 설정</p>
                    <p className='h10'>{formattedToday} -</p>
                </div>
            </div>
        )
    } else if (type === 'segment') {
        return (
            <div style={{width: '345px', borderRight: '1px solid #eee'}}>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedToday}~`} onClick={(e) => handleCustomDate(e)}>
                    <p className='h8'>기간 설정</p>
                    <p className='h10'>{formattedToday} -</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={formattedToday} onClick={(e) => handleClickDate(e)}>
                    <p className='h8'>오늘</p>
                    <p className='h10'>{formattedToday}</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedYesterday}~${formattedToday}`} onClick={(e) => handleClickDate(e)}>
                    <p className='h8'>직전 1일</p>
                    <p className='h10'>{formattedYesterday} - {formattedToday}</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedPast7}~${formattedToday}`} onClick={(e) => handleClickDate(e)}>
                    <p className='h8'>직전 3일</p>
                    <p className='h10'>{formattedPast3} - {formattedToday}</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedPast30}~${formattedToday}`} onClick={(e) => handleClickDate(e)}>
                    <p className='h8'>직전 7일</p>
                    <p className='h10'>{formattedPast7} - {formattedToday}</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedThisMonth}~${formattedToday}`} onClick={(e) => handleClickDate(e)}>
                    <p className='h8'>직전 14일</p>
                    <p className='h10'>{formattedPast14} - {formattedToday}</p>
                </div>
                <div className={anlStyle.Calendar__Selection__List} data-value={`${formattedStartDay}~${formattedToday}`} onClick={(e) => handleClickDate(e)}>
                    <p className='h8'>모두</p>
                    <p className='h10'>{formattedStartDay} - {formattedToday}</p>
                </div>
            </div>
        )
    }

}

const CalendarRange = (props) => {
    const {setDisplayedDate, displayedRange, setDisplayedRange, startDate, endDate, dateRange, updateDateRange} = props;
    // const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    // const [startDate, endDate] = dateRange;
    
    const formatDate = (m, d, y) => {
        return `${m+1}월 ${d}일, ${y}`;
    }
    // const [displayedRange, setDisplayedRange] = useState(formatDate(startDate?.getMonth(), startDate?.getDate(), startDate?.getFullYear()))

    // const updateDateRange = (update) => {
    //     setDateRange(update);
    // }

    // useEffect(() => {
    //     const newDate = `${formatDate(startDate.getMonth(), startDate.getDate(), startDate.getFullYear())} ${endDate ? (' ~ ' + formatDate(endDate.getMonth(), endDate.getDate(), endDate.getFullYear())): ''}`;
    //     setDisplayedRange(newDate);
    //     setDisplayedDate(newDate);
    // } ,[dateRange])

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <div></div>
                <div className={anlStyle.Calendar__DatePicker__Display}>
                    <p className='h6'>{displayedRange}</p>
                </div>
            </div>
            <div className='spacing-32' />
            <DatePicker
                width="100%"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    updateDateRange(update)
                }}
                showIcon
                isClearable={true}
                isShow={true}
                inline
            />
        </div>
    );
};