import crsStyle from '../../style/units.module.css';

export const Carousel = (props) => {
    const { size, dir } = props;
    const mapArr = new Array(size === 'multi' ? 3 : 1).fill(0);
    return (
        <div className={crsStyle.Carousel__Container}>
            {
                mapArr.map((data, idx) => {
                    return (
                        dir === 'carousel-vertical' ?
                        <CarouselVertical /> :
                        <CarouselHorizontal />
                    )
                })
            }
        </div>
    )
}

export const CarouselVertical = () => {
    return (
        <div className={crsStyle.Carousel__Wrap}>
            <img src='/img/units/carousel-image.png' width={150} height={150} />
            <div className='spacing-8' />
            <div>
                <div className={crsStyle.Carousel__Label}>
                    <p className='h14 fc-prim-800'>추천</p>
                </div>
                <div className='spacing-4' />
                <p className='h9 fc-gray-800'>탄테로 소리 그라엘라 모스카토 다스티 파리엔테 탄..</p>
                <div className='spacing-8' />
                <p className='h13 fc-gray-400' style={{ textDecoration: 'line-through' }}>99,999,999</p>
                <div style={{ display: 'flex' }}>
                    <span className='h6 fc-warning'>22%</span>
                    <div className='spacing-4' />
                    <span className='h6 fc-gray-800'>99,999,999원</span>
                </div>
                <div className='spacing-4' />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src='/img/units/carousel-star.png' width={14} height={14} />
                    <span className='h13 fc-gray-500'>4.8 (999)</span>
                </div>
                <div className='spacing-8' />
                <p className='h12 fc-gray-400'>스파이시 · 부드러운</p>
            </div>
        </div>
    )
}

export const CarouselHorizontal = () => {
    return (
        <div className={crsStyle.Carousel__Horizontal__Wrap}>
            <img src='/img/units/carousel-image.png' width={105} height={140} />
            <div className='spacing-8' />
            <div>
                <div className={crsStyle.Carousel__Label}>
                    <p className='h14 fc-prim-800'>추천</p>
                </div>
                <div className='spacing-4' />
                <p className='h9 fc-gray-800'>탄테로 소리 그라엘라 모스카토 다스티 파리엔테..</p>
                <div className='spacing-8' />
                <p className='h13 fc-gray-400' style={{ textDecoration: 'line-through' }}>99,999,999</p>
                <div style={{ display: 'flex' }}>
                    <span className='h6 fc-warning'>22%</span>
                    <div className='spacing-4' />
                    <span className='h6 fc-gray-800'>99,999,999원</span>
                </div>
                <div className='spacing-4' />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src='/img/units/carousel-star.png' width={14} height={14} />
                    <span className='h13 fc-gray-500'>4.8 (999)</span>
                </div>
                <div className='spacing-8' />
                <p className='h12 fc-gray-400'>스파이시 · 부드러운</p>
            </div>
        </div>
    )
}