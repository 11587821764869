import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './style/global.css';
import reportWebVitals from './reportWebVitals.js';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/Main.jsx';
import Dashboard from './components/Main/Dashboard.jsx';
import Product from './components/Products/Product.jsx';
import Usage from './components/Usage/Usage.jsx';
import { Insight, InsightDLST } from './components/Insight/Insight.jsx';
import MainDLST from './pages/MainDLST.jsx';
import Messages from './components/Messages/MessagesOld.jsx';
import Analytics from './components/Analytics/Analytics.jsx';
import CompTest from './pages/CompTest.jsx';
import Prep from './components/Prep.jsx';
import { AgentCreate, AgentCustom } from './components/Agent/AgentCreate.jsx';
import { Provider } from 'react-redux';
import store from './redux/store.js'
import { AgentList } from './components/Agent/AgentList.jsx';
import Segment from './components/Messages/Segment.jsx';
import Login from './pages/Login.jsx';

const basename = process.env.PUBLIC_URL;
const routes = [
  {
    path: '',
    element: <Main />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/agent/create/:email',
        element: <AgentCreate />,
      },
      {
        path: '/agent/custom/:email',
        element: <AgentCustom />,
      },
      {
        path: '/agent/list/:email',
        element: <AgentList />,
      },
      {
        path: 'insight',
        element: <Insight />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'products',
        element: <Product />,
      },
      {
        path: '/messages/segment/:email',
        element: <Segment />,
      },
      {
        path: 'usage',
        element: <Usage />,
      },
    ]
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'dlst',
    element: <MainDLST />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'insight',
        element: <InsightDLST />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'messages',
        element: <Messages />,
      },
      {
        path: 'usage',
        element: <Usage />,
      },
    ]
  },
  {
    path: 'test',
    element: <CompTest />
  }
]

const router = createBrowserRouter(routes, {basename: basename});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
