import { useEffect, useState } from 'react';
import { cardKeywordData, cardProductData, initKeywordCardData, initKeywordTrendData, initProductCardData, keyword10Data, keywordCardData, keywordTrendData } from '../../data/analyticsData';
import anlStyle from '../../style/analytics.module.css';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getKeyword10, getKeywordRelated, getModalGraphData, getModalKeyword, getModalProduct } from '../../apis/api/analytics/kpis';
import { convertModalGraphData } from '../../apis/api/analytics/utils';

export const KeywordList = (props) => {
    const {from, to} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userId = sessionStorage.getItem('user');
    const [keywordData, setKeywordData] = useState();
    const [modalData, setModalData] = useState(
        {
            rank: '',
            keyword: '',
            entity: '',
        }
    )

    const updateModalData = (newValue) => {
        setModalData({
            rank: newValue.rank,
            keyword: newValue.keyword,
            entity: newValue.entity,
        })
    }
    
    useEffect(() => {
        getKeyword10(userId, from, to)
            .then(res => setKeywordData(res));
    }, [from, to])

    return (
        <>
            <p className='h4 fc-gray-700'>🔥 키워드 TOP 10</p>
            <div className='spacing-4' />
            <p className='h7 fc-gray-600'>설정한 기간 동안 사용자의 대화에서 가장 많이 검색된 상위 10개의 키워드를 보여줍니다.</p>
            <div className='spacing-16' />
            <hr className={anlStyle.Analytics__BreakLine} />
            <div className='spacing-32' />
            <div className={anlStyle.Analytics__Table__Wrap}>
                <table className={anlStyle.Analytics__Table}>
                    <thead>
                        <tr>
                            <th className={anlStyle.Analytics__Table__Head} />
                            {
                                keyword10Data.head.map((th, idx) => {
                                    return (
                                        <th key={idx} className={anlStyle.Analytics__Table__Head}>
                                            <p className='h6'>{th}</p>
                                        </th>
                                    )
                                })
                            }
                            <th className={anlStyle.Analytics__Table__Head} />
                        </tr>
                    </thead>
                    <tbody>
                            {
                                keywordData?.map((td, idx) => {
                                    return (
                                        <tr 
                                            key={idx}
                                            onClick={() => {
                                                updateModalData(td)
                                                setIsModalOpen(true)
                                            }}
                                        >
                                            <td className={anlStyle.Analytics__Table__Body__underline} />
                                            <td className={`${anlStyle.Analytics__Table__Body__underline} pointer`}>
                                                <p className='h7'>{td.rank}</p>
                                            </td>
                                            <td className={`${anlStyle.Analytics__Table__Body__underline} pointer`}>
                                                <p className='h7'>{td.keyword}</p>
                                            </td>
                                            <td className={`${anlStyle.Analytics__Table__Body__underline} pointer`}>
                                                <p className='h7'>{td.entity}</p>
                                            </td>
                                            <td className={`${anlStyle.Analytics__Table__Body__underline} pointer`}>
                                                <p className='h7'>{td.count}</p>
                                            </td>
                                            <td className={`${anlStyle.Analytics__Table__Body__underline} pointer`}>
                                                <p className='h7'>{td.mostTotalCountItem}</p>
                                            </td>
                                            <td className={`${anlStyle.Analytics__Table__Body__underline} pointer`}>
                                                <p className='h7'>{td.mostClickedCountItem}</p>
                                            </td>
                                            <td className={anlStyle.Analytics__Table__Body__underline} />
                                        </tr>
                                    )
                                })
                            }
                    </tbody>
                </table>
                {
                    keywordData?.length === 0 &&
                    <div className={'flex flexcol f-center'}>
                        <div className='spacing-56' />
                        <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                        <div className='spacing-4' />
                        <p className='h7 fc-gray-500'>No Data</p>
                        <p className='h7 fc-gray-500'>데이터가 없습니다</p>
                        <div className='spacing-56' />
                    </div>
                }
            </div>
            {
                isModalOpen ?
                <div className={anlStyle.Analytics__Dimmed}>
                    <KeywordModal 
                        setIsModalOpen={setIsModalOpen} 
                        modalData={modalData}
                        from={from}
                        to={to}
                    />
                </div> :
                null
            }
        </>
    )
}

export const KeywordModal = (props) => {
    const {setIsModalOpen, modalData, from, to} = props;
    const userId = sessionStorage.getItem('user');
    const [productCardData, setProductCardData] = useState(initProductCardData);
    const [keywordCardData, setKeywordCardData] = useState(initKeywordCardData);
    const [graphData, setGraphData] = useState(initKeywordTrendData);
    
    useEffect(() => {
        getModalProduct(userId, from, to, modalData.keyword, modalData.entity)
            .then(res => {
                let copy = [...productCardData];
                copy[0].body = res.total;
                copy[1].body = res.clicked;
                setProductCardData(copy);
            })
        getModalKeyword(userId, from, to, modalData.keyword, modalData.entity)
            .then(res => {
                let copy = [...keywordCardData];
                copy[0].body = res;
                setKeywordCardData(copy);
            })
        getModalGraphData(userId, from, to, modalData.keyword, modalData.entity)
            .then(res => convertModalGraphData(res))
            .then(res => setGraphData(res))
    }, [])

    return (
        <div className={anlStyle.Analytics__KeywordModal__Wrap}>
            <div className={anlStyle.Analytics__KeywordModal__Header}>
                <div className={anlStyle.Analytics__KeywordModal__Header__top}>
                    <p className='h4 fc-gray-600'>키워드 #{modalData.rank}</p>
                    <img 
                        src='/img/units/toast-close.png' width={48} height={48} 
                        className='pointer'
                        onClick={() => setIsModalOpen(false)}
                    />
                </div>
                <div className={anlStyle.Analytics__KeywordModal__Header__title}>
                    <span className='h1'>{modalData.keyword}</span>
                    <div className='spacing-16' />
                    <span className='h7'>{modalData.entity}</span>
                </div>
            </div>
            <div className='spacing-32' />
            <div className={anlStyle.Analytics__KeywordModal__RankingWrap}>
                {
                    productCardData.map((data, idx) => {
                        return (
                            <div key={idx} className={anlStyle.Analytics__KeywordModal__RankingCard}>
                                <p className='h4 fc-gray-700'>{data.title}</p>
                                <div className='spacing-32' />
                                <div className={anlStyle.Analytics__Table__Wrap}>
                                    <table className={anlStyle.Analytics__Table}>
                                        <thead>
                                            <tr>
                                                <th className={anlStyle.Analytics__KeywordModal__TableHead}/>
                                                {
                                                    data.head.map((th, thIdx) => {
                                                        return (
                                                            <th key={thIdx} className={anlStyle.Analytics__KeywordModal__TableHead}>
                                                                <p className='h6'>{th}</p>
                                                            </th>
                                                        )
                                                    })
                                                }
                                                <th className={anlStyle.Analytics__KeywordModal__TableHead}/>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {
                                                    data.body.map((td, tdIdx) => {
                                                        return (
                                                            <tr key={tdIdx}>
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody} />
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody}>
                                                                    <p className='h7'>{td.rank}</p>
                                                                </td>
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody}>
                                                                    <span className='h7' style={{textDecoration: 'underline'}}>{`${td.name} `}</span>
                                                                    <a href={td.productUrl}>
                                                                        <img src='/img/analytics/analytics-open.png' width={14} height={14} />
                                                                    </a>
                                                                </td>
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody} />
                                                            </tr>
                                                        )
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    keywordCardData.map((data, idx) => {
                        return (
                            <div key={idx} className={anlStyle.Analytics__KeywordModal__RankingCard}>
                                <p className='h4 fc-gray-700'>{data.title}</p>
                                <div className='spacing-32' />
                                <div className={anlStyle.Analytics__Table__Wrap}>
                                    <table className={anlStyle.Analytics__Table}>
                                        <thead>
                                            <tr>
                                                <th className={anlStyle.Analytics__KeywordModal__TableHead}/>
                                                {
                                                    data.head.map((th, thIdx) => {
                                                        return (
                                                            <th key={thIdx} className={anlStyle.Analytics__KeywordModal__TableHead}>
                                                                <p className='h6'>{th}</p>
                                                            </th>
                                                        )
                                                    })
                                                }
                                                <th className={anlStyle.Analytics__KeywordModal__TableHead}/>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {
                                                    data.body.map((td, tdIdx) => {
                                                        return (
                                                            <tr key={tdIdx}>
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody} />
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody}>
                                                                    <p className='h7'>{td.rank}</p>
                                                                </td>
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody}>
                                                                    <span className='h7' style={{textDecoration: 'underline'}}>{`${td.keyword} `}</span>
                                                                </td>
                                                                <td className={anlStyle.Analytics__KeywordModal__TableBody} />
                                                            </tr>
                                                        )
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={anlStyle.Analytics__KeywordModal__TrendWrap}>
                <div className={anlStyle.Analytics__KeywordModal__TrendCard}>
                    <p className='h4 fc-gray-700'>노출량 추이</p>
                    <div className='spacing-32' />
                    <ResponsiveContainer width='100%' height={322}>
                        <LineChart 
                            width='100%' height='100%' 
                            data={graphData}
                            margin={{ right: 30, left: 10 }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis dataKey="name" tickLine={false} padding={{ left: 30, right: 30 }} axisLine={{stroke: '#e1e1e1'}} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Tooltip />
                            <Line type="monotone" dataKey="노출량" stroke="#154cca" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}