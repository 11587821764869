import { colorSet, colors } from "../../style/colorVar.js";
import { getFormattedDate } from "../api/utils.js";

export const getKeywordsList = (rawKeywordData) => {
    // console.log('rawKeywordData: ', rawKeywordData);
    let topKeywordsArr = [];
    rawKeywordData.top_keywords.map((keyword) => {topKeywordsArr.push(keyword.keyword)});
    return {
        dialogues: rawKeywordData.dialogues,
        topKeywordsObj: rawKeywordData.top_keywords,
        topKeywordsArr: topKeywordsArr,
    }
}

export const getWAUList = (rawWAUData) => {
    // let thisMonday = new Date();
    // thisMonday.setDate(thisMonday.getDate() - thisMonday.getDay() + 1);
    // thisMonday = getFormattedDate(thisMonday, 'semi');
    // console.log('thisMonday: ', thisMonday);

    // const WAUList = [rawWAUData.thisWeek];
    // WAUList[0].week = thisMonday;
    // rawWAUData.weeks.map(data => WAUList.push(data));
    // console.log('WAU list: ', WAUList);

    return rawWAUData.weeks
}

export const getClickRateList = (rawClickRateData) => {
    // console.log('rawClickRateData: ', rawClickRateData);

    return {
        recommendCount: [
            rawClickRateData.recommend_count.weeks,
            rawClickRateData.recommend_count.thisWeek,
        ],
        clickRate: [
            rawClickRateData.click_rate.weeks?.toFixed(1),
            rawClickRateData.click_rate.thisWeek?.toFixed(1),
        ],
    }
}

export const getProdRateList = (rawProdRateData) => {
    // console.log('rawProdRateData: ', rawProdRateData);

    return {
        impression: rawProdRateData.impressios,
        rate: rawProdRateData.rate,
        total: (rawProdRateData.impressios / rawProdRateData.rate) * 100,
    }
}

export const getInsightList = (rawInsightData) => {
    // user numbers converted to ratio(%)
    const totalUserNums = Object.entries(rawInsightData.user_num).reduce((acc, [key, value]) => {
        return acc + value;
    }, 0);
    const userNumsRatio = Object.entries(rawInsightData.user_num).reduce((userNumsRatio, [key, value]) => {
        userNumsRatio[key] = ((value / totalUserNums) * 100).toFixed(0);
        return userNumsRatio;
    }, {'totalUserNums': totalUserNums}); 

    // scatter coordinates conversion
    const coordinates = {
        recommend: [],
        information: [],
    };
    Object.values(rawInsightData.coordinate.recommend).map(val => coordinates.recommend.push({x: val[0], y: val[1]}));
    Object.values(rawInsightData.coordinate.information).map(val => coordinates.information.push({x: val[0], y: val[1]}));
    
    const areaTitle = ['구체적-많은 대화', '추상적-많은 대화', '추상적-적은 대화', '구체적-적은 대화'];
    const areaTitleKey = ['first', 'second', 'third', 'fourth'];
    const tableData = [];

    areaTitleKey.map((data, idx) => {
        tableData.push({
            'title': areaTitle[idx],
            'contents': rawInsightData.keywords[data],
        })
    })

    return {
        userNums: userNumsRatio,
        coordinates: coordinates,
        tableData: tableData,
    }
}

export const getFeedList = (rawFeedData) => {
    const feedListArr = [];

    rawFeedData.map((data, idx) => {
        feedListArr.push({
            date: data.date,
            feedArr: data.feed,
        })
    })

    return feedListArr
}

export const getFreqList = (freqObj) => {
    let freqListArr = [];

    Object.entries(freqObj).map(([key, value], idx) => {
        freqListArr.unshift({
            date: key,
            '빈도수': value,
        })
    })

    return freqListArr;
}