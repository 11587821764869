import { useEffect, useState } from 'react';
import { initSegmentFilterData, listBodyInitObj, segmentListInitData } from '../../data/segmentData';
import msgStyle from '../../style/messages.module.css';
import unitStyle from '../../style/units.module.css';
import { getSegmentFilters } from '../../apis/api/segment/kpis';


const SegmentList = (props) => {
    const { segmentListData, addBodyList } = props;
    const [segmentList, setSegmentList] = useState()
    const userId = sessionStorage.getItem('user');

    useEffect(() => {
        if (!sessionStorage.getItem('segmentListData')) {
            sessionStorage.setItem('segmentListData', JSON.stringify(segmentListInitData));
        }
        sessionStorage.setItem('segmentFilter', JSON.stringify(initSegmentFilterData));

        getSegmentFilters(userId)
            .then(res => setSegmentList(res))
    }, [])

    return (
        <div className={msgStyle.Messages__Contents}>
            <div className={msgStyle.Messages__Top}>
                <div className={unitStyle.Button__primary} style={{width: '166px', height: '42px'}}>
                    <p 
                        className='h6 fc-white pointer'
                        onClick={() => addBodyList()}
                    >+ 세그먼트 생성하기</p>
                </div>
            </div>
            <div className='spacing-24' />
            <div className={msgStyle.Messages__Table__Wrap}>
                <table className={msgStyle.Messages__Table}>
                    <thead>
                        <tr>
                            <th className={msgStyle.Messages__Table__Head} />
                            {
                                segmentListData?.head.map((th, idx) => {
                                    return (
                                        <th key={idx} className={msgStyle.Messages__Table__Head}>
                                            <p className='h6'>{th}</p>
                                        </th>
                                    )
                                })
                            }
                            <th className={msgStyle.Messages__Table__Head} />
                        </tr>
                    </thead>
                    <tbody>
                            {
                                segmentList?.map((td, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td className={msgStyle.Messages__Table__Body} />
                                            <td className={msgStyle.Messages__Table__Body}>
                                                <p className='h7'>{td.name}</p>
                                            </td>
                                            <td className={msgStyle.Messages__Table__Body}>
                                                <p className='h7'>활성화</p>
                                            </td>
                                            <td className={msgStyle.Messages__Table__Body}>
                                                <p className='h7'>{td.createdTime}</p>
                                            </td>
                                            <td className={msgStyle.Messages__Table__Body}>
                                                <p className='h7'>
                                                    <img src='/img/messages/messages-edit.png' width={32} height={32} />
                                                </p>
                                            </td>
                                            <td className={msgStyle.Messages__Table__Body}>
                                                <p className='h7'>
                                                    <a href={td.csvUrl}>
                                                        <img src='/img/messages/messages-download.png' width={32} height={32} />
                                                    </a>
                                                </p>
                                            </td>
                                            <td className={msgStyle.Messages__Table__Body} />
                                        </tr>
                                    )
                                })
                            }
                    </tbody>
                </table>
                {
                    segmentList === undefined || segmentList.length === 0 &&
                    <div className={msgStyle.Messages__NodataWrap}>
                        <div className='spacing-56' />
                        <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                        <div className='spacing-4' />
                        <p className='h7 fc-gray-500'>No Data</p>
                        <p className='h7 fc-gray-500'>세그먼트 리스트가 비었어요</p>
                        <div className='spacing-56' />
                    </div>
                }
            </div>
        </div>
    )
}

export default SegmentList