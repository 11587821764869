import styled from 'styled-components';
import { colors } from '../style/colorVar';

const Prep = () => {
    return (
        <Container>
            <FlexWrap>
                <Contents>열심히 준비 중이에요! 🐧</Contents>
            </FlexWrap>
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.base};
    border-radius: 10px;
`

const FlexWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Contents = styled.span`
    font-size: 2em;
    font-weight: 800;
    color: ${colors.fontGray};
`

export default Prep