import styled, { css, keyframes } from 'styled-components';
import { colorSet } from './colorVar';

function getHeight({ axis, size }) {
    return axis === 'horizontal' ? 1 : size;
}

function getWidth({ axis, size }) {
    return axis === 'vertical' ? 1 : size;
}

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

export const fadeOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`

export const Spacer = styled.span`
    display: block;
    width: ${getWidth}px;
    min-width: ${getWidth}px;
    height: ${getHeight}px;
    min-height: ${getHeight}px;
`

export const Container = styled.div`
    width: ${props => props.$width || '100%'};
    height: ${props => props.$height || 'fit-content'};
    margin: ${props => props.$margin || '0'};
    padding: ${props => props.$padding || '0'};
    visibility: ${props => props.$visibility || 'visible'};
    max-width: ${props => props.$maxWidth || ''};
`

export const GridContainer = styled(Container)`
    display: grid;
    grid-template-columns: ${props => props.$gridCol || ""};
    grid-template-rows: ${props => props.$gridRow || ""};
    grid-auto-flow: column;
    grid-column-start: ${props => props.$gridColStart || ""};
    grid-column-end: ${props => props.$gridColEnd || ""};
    gap: ${props => props.$gap || ""};
`

export const FlexContainer = styled(Container)`
    display: flex;
    flex-direction: ${props => props.$flexDir || 'row'};
    flex: ${props => props.$flex || ''};
    flex-wrap: ${props => props.$flexWrap || 'wrap'};
    align-items: ${props => props.$alignItems || 'center'};
    justify-content: ${props => props.$justifyContent || 'center'};
    gap: ${props => props.$gap || ""};
`

export const ChartBox = styled(Container)`
    background: ${props => props.$background || colorSet.scnd50};
    border-radius: ${props => props.$borderRadius || '5px'};
    margin: ${props => props.$margin || 0};
    padding: ${props => props.$padding || '8px'};
    box-shadow: ${props => props.$boxShadow || "3px 3px 10px rgba(0, 0, 0, 0.25)"};

    transition: all 0.5s;
`

export const FadeChartBox = styled(ChartBox)`
    opacity: 0;
    animation: ${fadeIn} 0.1s ease-in-out forwards;

    ${props => 
        props.out && 
        css`
            animation: ${fadeOut} 0.1s ease-in-out forwards;
        `
    }
`

export const ChartTitle = styled.p`
    font-size: 16px;
    margin: ${props => props.$margin || '8px 0'};
    padding: ${props => props.$padding || '0'};
`

export const ContentsBox = styled(Container)`
    background: ${colorSet.scnd100};
    border-radius: 10px;
`

export const TextDeco = styled.p`
    font-size: ${props => props.$fontSize || '16px'};
    font-weight: ${props => props.$fontWeight || '400'};
    color: ${props => props.$fontColor || colorSet.scnd900};
    text-align: ${props => props.textAlign || 'left'};
    margin: ${props => props.$margin || '0'};
    padding: ${props => props.$padding || '0'};
`

export const IconWrap = styled.div`
    width: ${props => props.$width || '30px'};
    height: ${props => props.$height || '30px'};
    background: ${props => props.$background || '#ddd'};
    margin: ${props => props.$margin || 0};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`