import styled, { css } from 'styled-components';
import { colorSet } from '../../style/colorVar';
import { useState } from 'react';
import { ChartBox, Container, FlexContainer } from '../../style/Units';

const Usage = () => {
    let [toggle, setToggle] = useState(true);
    let [selectedLabelArr, setSelectedLabelArr] = useState([false, true]);

    const handleToggleclick = () => {
        setToggle(!toggle);
        setSelectedLabelArr(selectedLabelArr.map(data => !data));
    }

    return (
        <Container>
            <ChartBox $padding="24px">
                <FlexContainer $justifyContent="flex-end">
                    <ToggleLabel $selected={selectedLabelArr[0]}>월 결제</ToggleLabel>
                    <ToggleWrap onClick={handleToggleclick} $shift={toggle}>
                        <Toggle $shift={toggle}></Toggle>
                    </ToggleWrap>
                    <ToggleLabel $selected={selectedLabelArr[1]}>연 결제</ToggleLabel>
                    <ToggleLabel $fontSize="14px" $selected={selectedLabelArr[1]} $margin="0" $point>-20%</ToggleLabel>
                </FlexContainer>
                <ImgWrap>
                    {
                        selectedLabelArr[0] ?
                        <img src={`${process.env.PUBLIC_URL}/img/plans/monthly.png`} width="100%" alt='monthly plan'/> :
                        <img src={`${process.env.PUBLIC_URL}/img/plans/yearly.png`} width="100%" alt='yearly plan'/>
                    }
                </ImgWrap>
            </ChartBox>
        </Container>
    )
};

const ToggleWrap = styled.div`
    width: 44px;
    height: 24px;
    border-radius: 12px;
    background: ${props => props.$shift ? colorSet.main : colorSet.scnd300};
    transition: all 0.5s;
`

const Toggle  = styled.div`
    width: 20px;
    height: 20px;
    margin: 2px;
    transform: ${props => props.$shift && 'translateX(20px)'};
    border-radius: 50%;
    background: ${colorSet.scnd50};
    transition: all 0.5s;
`

const ToggleLabel = styled.p`
    font-size: ${props => props.$fontSize || "16px"};
    font-family: "NanumSquareNeo";
    margin: ${props => props.$margin || "0 8px"};
    color: ${props => !props.$selected && colorSet.scnd300};
    transition: all 0.5s;

    ${props => 
        props.$point &&
        css`
            color: ${props => props.$selected ? colorSet.main : colorSet.scnd300};
            font-weight: 600;
        `
    }
`

const ImgWrap = styled.div`
    width: 100%;
    height: fit-content;
    padding: 50px 0;
`

export default Usage