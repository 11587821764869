
export const convertPieData = (rawData) => {
    let convertedRes = new Object();

    const convertData = (data) => {
        let converted = new Array();
        let subContents = new Array();
        data?.forEach(elem => {
            if (elem.entity === '기타' || elem.keyword === '기타') {
                elem.content.forEach(sub => {
                    subContents.push({
                        name: sub.entity || sub.keyword,
                        value: sub.count,
                    })
                })
                converted.push({
                    name: elem.entity || elem.keyword,
                    value: elem.count,
                    selected: subContents,
                })
            } else {
                converted.push({
                    name: elem.entity || elem.keyword,
                    value: elem.count,
                })
            }
        })
        
        return converted;
    };

    convertedRes.entity = convertData(rawData.entity);
    convertedRes.keyword = convertData(rawData.keyword);

    return convertedRes;
}

export const convertFilterOption = (data) => {
    let convertedRes = new Object();
    convertedRes.entity = new Array();
    convertedRes.keyword = new Array();

    data.entity?.map((ent) => {
        convertedRes.entity.push(ent.entity);
    })

    data.keyword?.map((key) => {
        convertedRes.keyword.push(key.keyword)
    })

    return convertedRes;
}

export const convertModalGraphData = (data) => {
    let convertedRes = new Array();

    data.map((obj) => {
        convertedRes.push({
            name: obj.date,
            노출량: obj.frequency,
        })
    })

    return convertedRes;
}