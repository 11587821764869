import axios from 'axios';

export const postSegmentFilters = async (segName, userId, filters, from, to) => {
    try {
        const response = await axios.post(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/segment',
            {
                segName: segName,
                randomId: userId,
                filters: filters,
                from: from,
                to: to,
            },
            {
                headers: {}
            }
        )
        console.log('postSegmentFilters res, ', response.data.data);
    } catch (error) {
        console.error(`Error while calling postSegmentFilters API: ${error}`);
    }
}

export const getSegmentFilters = async (userId) => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/segment', {
            params: {
                randomId: userId,
            },
            headers: {}
        })
        return response.data.segment;
    } catch (error) {
        console.error(`Error while calling getSegmentFilters API: ${error}`);
    }
}