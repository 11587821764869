import styled from 'styled-components';
import { colorSet } from '../../style/colorVar';
import { FlexContainer } from '../../style/Units';
import { getFormattedDate } from '../../apis/api/utils';
import { useEffect, useState } from 'react';

export const TopNav = (props) => {
    const { background } = props;
    const setSelectedDate = props.setSelectedDate;

    let date = new Date();
    const maxWeek = 4;
    let thisMonday = new Date();
    thisMonday.setDate(thisMonday.getDate() - date.getDay() + 1);
    thisMonday = getFormattedDate(thisMonday);
    let lastMonday = new Date(date);
    lastMonday.setDate(date.getDate() - date.getDay() - 6);
    let startDateArr = new Array(maxWeek);
    let endDateArr = new Array(maxWeek);

    for (var i=0; i<maxWeek; i++) {
        const endDate = new Date(lastMonday);
        endDate.setDate(lastMonday.getDate() + 6);
        startDateArr[i] = getFormattedDate(lastMonday);
        endDateArr[i] = getFormattedDate(endDate);
        lastMonday.setDate(lastMonday.getDate() - 7);
    }

    const handleSelect = () => {
        const selected = document.getElementById('period');
        const selectedValue = selected.value;
        setSelectedDate(new Date(selectedValue));
    }

        const location = window.location.pathname;
        const [page, setPage] = useState('');
        const [icon, setIcon] = useState('');

        useEffect(() => {
            console.log(location);
            switch(true) {
                case location.includes('agent/c'):
                    setPage('젠투 에이전트 생성');
                    setIcon('/img/main/main-gentoo-agent.png');
                    break;
                case location.includes('agent/list'):
                    setPage('젠투 에이전트 리스트');
                    setIcon('/img/main/main-gentoo-agent.png');
                    break;
                case location.includes('insight'):
                    setPage('인사이트');
                    setIcon('/img/main/main-insight.png');
                    break;
                case location.includes('analytics'):
                    setPage('젠투 에이전트 분석');
                    setIcon('/img/main/main-analytics.png');
                    break;
                case location.includes('segment'):
                    setPage('세그먼트 리스트');
                    setIcon('/img/main/main-messages.png');
                    break;
                case location.includes('usage'):
                    setPage('요금');
                    setIcon('/img/main/main-plans.png');
                    break;
                default:
                    setPage('대시보드');
                    setIcon('/img/main/main-dashboard.png');
                    break;
            }
        }, [location])

    return (
        <Container>
            <FlexContainer $width="auto">
                <MenuIcons $background={background} />
                <img src={icon} width={24} height={24} />
                <div className='spacing-8' />
                <p className='h4'>{page}</p>
            </FlexContainer>
            {/* {
                sessionStorage.getItem('login') === 'demo' ?
                <StyledSelect id="period" name="period" onChange={handleSelect}>
                    <option value={'2023-09-04'}>2023-09-04 ~ 2023-09-10</option>
                    <option value={'2023-08-28'}>2023-08-28 ~ 2023-09-03</option>
                </StyledSelect> :
                <StyledSelect id="period" name="period" onChange={handleSelect}>
                    <option value={thisMonday}>This week</option>
                    <option value={startDateArr[0]}>{startDateArr[0]} ~ {endDateArr[0]}</option>
                    <option value={startDateArr[1]}>{startDateArr[1]} ~ {endDateArr[1]}</option>
                    <option value={startDateArr[2]}>{startDateArr[2]} ~ {endDateArr[2]}</option>
                    <option value={startDateArr[3]}>{startDateArr[3]} ~ {endDateArr[3]}</option>
                </StyledSelect>
            } */}
        </Container>
    )
}

export const TopNavDLST = (props) => {
    const { page, background } = props;
    const setSelectedDate = props.setSelectedDate;

    let date = new Date();
    const maxWeek = 4;
    let thisMonday = new Date();
    thisMonday.setDate(thisMonday.getDate() - date.getDay() + 1);
    thisMonday = getFormattedDate(thisMonday);
    let lastMonday = new Date(date);
    lastMonday.setDate(date.getDate() - date.getDay() - 6);
    let startDateArr = new Array(maxWeek);
    let endDateArr = new Array(maxWeek);

    for (var i=0; i<maxWeek; i++) {
        const endDate = new Date(lastMonday);
        endDate.setDate(lastMonday.getDate() + 6);
        startDateArr[i] = getFormattedDate(lastMonday);
        endDateArr[i] = getFormattedDate(endDate);
        lastMonday.setDate(lastMonday.getDate() - 7);
    }

    const handleSelect = () => {
        const selected = document.getElementById('period');
        const selectedValue = selected.value;
        setSelectedDate(new Date(selectedValue));
    }

    return (
        <Container>
            <FlexContainer $width="auto">
                <MenuIcons $background={background} />
                <p>{page}</p>
            </FlexContainer>
            <StyledSelect id="period" name="period" onChange={handleSelect}>
                <option value={'2023-10-02'}>2023-10-02 ~ 2023-10-08</option>
                <option value={'2023-09-25'}>2023-09-25 ~ 2023-10-01</option>
            </StyledSelect>
        </Container>
    )
}

const StyledSelect = styled.select`
    width: 250px;
    border: none;
    padding: 4px 16px;
    border-radius: 5px;
    font-size: 14px;
`

const Anchor = styled.div`
    position: absolute;
    width: 100%;
    height: 60px;
    top: 0;
    left: 280px;
`

const Container = styled.div`
    width: calc(100% - 240px);
    height: 70px;
    position: relative;
    top: 0;
    left: 240px;
    min-height: 40px;
    background-color: ${colorSet.scnd50};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #efefef;
`

const MenuIcons = styled.div`
    width: 30px;
    height: 30px;
    background: ${props => props.$background || "url(/img/menu/menu_overview_w300_scnd900.svg)"};
    margin-right: 8px;
`

const Logo = styled.div`
    width: 60px;
    height: 60px;
    background-image: url("/img/topnav/accio-logo.png");
    background-size: cover;
`

const Profile = styled.div`
    display: flex;
    align-items: center;

    p {
        margin-bottom: 4px;
        font-size: 14px;
    }
`

const ProfileImg = styled.div`
    width: 16px;
    height: 16px;
    background-image: url("/img/topnav/account_circle_FILL0_wght400_GRAD0_opsz48.svg");
    margin-right: 4px;
`

const DropImg = styled.div`
    width: 16px;
    height: 16px;
    background-image: url("/img/topnav/arrow_drop_down_FILL0_wght700_GRAD0_opsz48.svg");
    margin-bottom: 4px;
`