import styled from 'styled-components';
import Prep from '../Prep';
import { ChartBox, Container, FlexContainer, Spacer, TextDeco } from '../../style/Units';
import { TopNavDLST } from '../Main/TopNav';
import { useEffect, useState } from 'react';
import { colorSet } from '../../style/colorVar';
import { getPushMessages, postMsgLike, postPushMessages } from '../../apis/api/kpis';

const Messages = () => {
    let [selectedDate, setSelectedDate] = useState('2023-10-02');
    let [msgList, setMsgList] = useState();
    let [selectedFeed, setSelectedFeed] = useState('');
    let [likeCall, setLikeCall] = useState(false);
    let [likeArr, setLikeArr] = useState([]);


    const handleClickMsg = (e) => {
        let feed = e.currentTarget.dataset.feed;
        feed = JSON.parse(feed);
        setSelectedFeed(feed);
    }

    const handleLike = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const likeData = JSON.parse(e.currentTarget.dataset.like);
        // setSelectedFeed((prev) => {
        //     if (prev === 'messages') {
        //         prev.map((message, idx) => {
        //             if (message.messageId === likeData.messageId) {message.like = !message.like}
        //         })
        //     }
        // });
        setSelectedFeed((prev) => {
            if (prev && prev.messages) {
                return {
                    ...prev,
                    messages: prev.messages.map((message) => {
                        if (message.messageId === likeData.messageId) {
                            return { ...message, like: !message.like };
                        }
                        return message;
                    }),
                };
            }
            return prev;
        });
        
        postMsgLike(likeData);
        setLikeCall(!likeCall);
    }

    useEffect(() => {
        const msgListCall = () => {
            getPushMessages()
                .then((res) => {
                    setMsgList(res);
                    (selectedFeed || setSelectedFeed(res[0]));
                })
        }
        msgListCall();
    },[selectedFeed])

    return (
        <FlexContainer $flexDir={"column"} $flexWrap={"nowrap"} $height={"calc(100vh - 16px)"} $alignItems={"flex-start"} style={{overflowY: 'hidden'}}>
            <TopNavDLST page={"Messages"} background={"url(/img/menu/msg202020.svg)"} setSelectedDate={setSelectedDate} />
            <Spacer size={8} />
            <FlexContainer $justifyContent="space-between" $alignItems="flex-start">
                <ChartBox $width="33%" $padding="0" $height="calc(100vh - 68px)" style={{overflowY: "scroll"}}>
                    <DrawListWrap>
                        {
                            msgList && msgList.length > 0 ?
                            msgList?.map((data, idx) => {
                                return (
                                    <DrawList 
                                        key={idx}
                                        data-feed={JSON.stringify(data)} 
                                        dangerouslySetInnerHTML={{__html: data?.feedContent}} 
                                        onClick={(e) => {handleClickMsg(e)}}
                                        style={{
                                            color: (selectedFeed?.feedId === data?.feedId && colorSet.prim500),
                                            fontWeight: (selectedFeed?.feedId === data?.feedId && '800'),
                                            cursor: 'pointer'
                                        }}
                                    ></DrawList>
                                )
                            }) :
                            <DrawList>생성된 메세지가 없습니다.<br /> Insight 탭에서 메세지를 생성하고 싶은 피드를 확인해주세요!</DrawList>
                        }
                    </DrawListWrap>
                </ChartBox>
                <ChartBox $width="66.5%" $padding="12px 40px" $height="calc(100vh - 68px)" style={{overflowY: "scroll"}}>
                    <TextDeco $fontSize="20px" $fontWeight="800" dangerouslySetInnerHTML={{__html: selectedFeed?.feedContent}}></TextDeco>
                    <Spacer size={4} />
                    <TextDeco>클릭한 인사이트를 활용하여 오픈률을 높일 수 있는 CRM 메시지를 만들었어요.</TextDeco>
                    <Spacer size={24} />
                    {
                        selectedFeed &&
                        selectedFeed?.messages?.map((data, idx) => {
                            return (
                                <MsgContent key={idx}>
                                    <FlexContainer $justifyContent="space-between">
                                        <div>
                                            <div>{data.title}</div>
                                            <div>{data.content}</div>
                                        </div>
                                        <img 
                                            data-like={JSON.stringify(data)}
                                            onClick={(e) => handleLike(e)}
                                            src={data.like ? '/img/messages/heartFill.svg' : '/img/messages/heart.svg'} 
                                            width={20}
                                            style={{cursor: 'pointer'}}
                                        />
                                    </FlexContainer>
                                </MsgContent>
                            )
                        })
                    }
                </ChartBox>
            </FlexContainer>
        </FlexContainer>
    )
}

const DrawListWrap = styled.ul`
    height: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
`

const DrawList = styled.li`
    font-size: 14px;
    color: ${colorSet.scnd500};
    padding: 12px 20px;
    border-bottom: 1px solid #ddd;
    word-wrap: no-wrap;
`

const MsgContent = styled.div`
    width; 100%;
    padding: 16px 32px;
    margin: 0 0 8px 0;
    background: #e8eef8;
    border-radius: 10px;
`

export default Messages