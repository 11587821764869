import customStyle from '../../style/units.module.css';
import { SampleContents } from '../../data/unitsData';
import { useState } from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import { uploadImg } from '../../apis/services/create/utils';

export const LogoInput = (props) => {
    const {initLogo, updateSetting} = props;
    const checkFileSize = (e) => {
        const fileSize = e.target?.files[0]?.size;
        const sizeToMB = (fileSize / (1024 * 1024)).toFixed(1);

        if (sizeToMB > 2) {
            alert('2MB 이하 파일만 업로드 가능합니다');
        } else {
            uploadImg(e.target?.files[0], updateSetting);
        }
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>로고</p>
            <div className='spacing-32' />
            <div>
                <label htmlFor='profile'>
                    <img src='/img/units/preview-logoinput.png' width={80} height={80} />
                </label>
                <input 
                    type='file' id='profile' name='profile' accept='.png, .jpg' 
                    className='visually-hidden' 
                    onChange={(e) => checkFileSize(e)}
                />
                <p>.png .jpg 형식, 2MB 이하 파일만 업로드 가능합니다</p>
            </div>
        </div>
    )
}

export const NameInput = (props) => {
    const {initName, updateSetting} = props;

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>에이전트 이름</p>
            <div className='spacing-32' />
            <input 
                type='text' 
                defaultValue={initName}
                className={customStyle.CustomSet__TextInput}
                onChange={(e) => updateSetting('nameInput', e.target.value)}
            />
        </div>
    )
}

export const ColorInput = (props) => {
    const {initColor, updateSetting, setCarouselVisible} = props;
    const [background, setBackground] = useState(initColor);
    const [openPicker, setOpenPicker] = useState([false, false, false]);
    const [selected, setSelected] = useState();

    const handleChangeComplete = (e, idx) => {
        const newColor = {
            hex: e.hex,
            rgb: e.rgb,
            red: e.rgb.r,
            green: e.rgb.g,
            blue: e.rgb.b,
            alpha: e.rgb.a,
        }
        let newColorInput = initColor;
        newColorInput[idx] = newColor;

        updateSetting('colorInput', newColorInput);
    };

    const handleOpenPicker = (e, idx) => {
        e?.stopPropagation();
        let copy = [...openPicker];
        copy = [false, false, false];
        copy[idx] = true;
        setSelected(idx);
        setOpenPicker(copy);
        setCarouselVisible(false);
    }

    const handleClosePicker = (e) => {
        e?.stopPropagation();
        setSelected();
        setOpenPicker([false, false, false]);
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>테마 색상</p>
            <div className='spacing-32' />
            <div className={customStyle.CustomSet__ColorInput__Wrap}>
                {
                    background.map((data, idx) => {
                        return (
                            <div key={idx}>
                                <div className={selected === idx ? customStyle.CustomSet__ColorInput__active : customStyle.CustomSet__ColorInput}>
                                    <div className={customStyle.CustomSet__ColorInput__Circle} style={{background: `rgba(${data.red}, ${data.green}, ${data.blue}, ${data.alpha})`}} />
                                    <img 
                                        src='/img/units/custom-expand.png' width={24} height={24} 
                                        onClick={(e) => handleOpenPicker(e, idx)} 
                                    />
                                </div>
                                <p className='h12 fc-gray-500' style={{padding: '4px'}}>Color 0{idx+1}</p>
                                {
                                    openPicker[idx] ?
                                    <div>
                                        <div style={{position: 'relative', zIndex: 2}}>
                                            <div>
                                            <ChromePicker 
                                                color={ background[idx].rgb }
                                                onChange={ (e) => handleChangeComplete(e, idx) }
                                            />
                                            </div>
                                        </div>
                                        <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1}} onClick={(e) => {handleClosePicker(e)}} />
                                    </div> : 
                                    null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const GreetingInput = (props) => {
    const {initGreeting, updateSetting} = props;

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>시작 문구</p>
            <div className='spacing-32' />
            <input 
                type='text' 
                defaultValue={initGreeting}
                className={customStyle.CustomSet__TextInput}
                onChange={(e) => updateSetting('greetingInput', e.target.value)}
            />
        </div>
    )
}

export const RecommendSizeInput = (props) => {
    const {initRecommendSize, updateSetting, setCarouselVisible} = props;
    const [selected, setSelected] = useState(initRecommendSize);

    const handleClickInput = (e) => {
        e.stopPropagation();
        const value = e.target.value;
        updateSetting('recommendSizeInput', value);
        setSelected(value);
        setCarouselVisible(true);
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>상품 추천 개수</p>
            <div className='spacing-32' />
            <div className={customStyle.CustomSet__RecSizeWrap}>
                <div style={{textAlign: 'center'}}>
                    <label htmlFor='recommend-multi'>
                        <div className={`${customStyle.CustomSet__RecSize__multi} ${selected === 'multi' && customStyle.CustomSet__RecSize__activate}`}>
                            <img src='/img/units/custom-multicrs.png' width={116} />
                        </div>
                    </label>
                    <input 
                        type='radio' id='recommend-multi' name='recommend-size' value='multi'
                        className='visually-hidden'
                        onClick={(e) => {handleClickInput(e)}}
                    />
                    <div className='spacing-8' />
                    <p className={`h7 ${selected === 'multi' ? 'fc-prim-800' : 'fc-gray-600'}`}>여러 개의 상품</p>
                </div>
                <div style={{textAlign: 'center'}}>
                    <label htmlFor='recommend-single'>
                    <div className={`${customStyle.CustomSet__RecSize__single} ${selected === 'single' && customStyle.CustomSet__RecSize__activate}`}>
                            <img src='/img/units/custom-singlecrs.png' width={68} />
                        </div>
                    </label>
                    <input 
                        type='radio' id='recommend-single' name='recommend-size' value='single'
                        className='visually-hidden'
                        onClick={(e) => {handleClickInput(e)}}
                    />
                    <div className='spacing-8' />
                    <p className={`h7 ${selected === 'single' ? 'fc-prim-800' : 'fc-gray-600'}`}>단일 상품</p>
                </div>
            </div>
        </div>
    )
}

export const CarouselDirInput = (props) => {
    const {initCarouselDirInput, updateSetting} = props;
    const [selected, setSelected] = useState(initCarouselDirInput);

    const handleClickRadio = (e) => {
        updateSetting('carouselDirInput', e.target.id);
        setSelected(e.target.id);
    }
    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>이미지 비율</p>
            <div className='spacing-32' />
            <div className={customStyle.CustomSet__RadioWrapVert}>
                <div style={{display: 'flex'}}>
                    <input 
                        type='radio'
                        id='carousel-vertical' 
                        checked={selected === 'carousel-vertical' ? true : false}
                        onChange={(e) => handleClickRadio(e)}
                    />
                    <div className='spacing-16' />
                    <label htmlFor='carousel-vertical' className={`h7 ${selected === 'carousel-vertical' ? 'fc-prim-800' : 'fc-gray-600'}`}>세로형</label>
                </div>
                <div style={{display: 'flex'}}>
                    <input 
                        type='radio'
                        id='carousel-horizontal' 
                        checked={selected === 'carousel-horizontal' ? true : false}
                        onChange={(e) => handleClickRadio(e)}
                    />
                    <div className='spacing-16' />
                    <label htmlFor='carousel-horizontal' className={`h7 ${selected === 'carousel-horizontal' ? 'fc-prim-800' : 'fc-gray-600'}`}>가로형</label>
                </div>
            </div>
        </div>
    )
}

export const ExceptKeyword = (props) => {
    const {initKeywords, updateSetting} = props;
    const [isInputFocused, setisInputFocused] = useState(false);
    const [input, setInput] = useState('');
    const [inputArr, setInputArr] = useState(initKeywords);

    const handleFocusInput = () => {
        setisInputFocused(true);
    }

    const handleBlurInput = () => {
        if (input.length === 0) setisInputFocused(false);
    }

    const handleChangeInput = (e) => {
        setInput(e.target.value);
    }

    const handleClickAdd = (e) => {
        console.log('handleClickAdd called');
        if (input.length === 0) return;
        let arr = [...inputArr];
        arr.push(input);
        setInput('');
        setInputArr(arr);
        updateSetting('exceptKeyword', arr);
        setisInputFocused(false);
    }

    const handleClickDelete = (idx) => {
        let arr = [...inputArr];
        arr.splice(idx, 1);
        setInputArr(arr);
        updateSetting('exceptKeyword', arr);
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>제외 키워드</p>
            <div className='spacing-32' />
            <div style={{width: '100%'}}>
                <div className={customStyle.CustomSet__ExKeywords__TextInputWrap} style={{border: isInputFocused && `1px solid #154cca`}}>
                    <input 
                        type='text' 
                        placeholder=''
                        className={customStyle.CustomSet__ExKeywords__TextInput}
                        onFocus={() => handleFocusInput()}
                        onBlur={() => handleBlurInput()}
                        onChange={(e) => handleChangeInput(e)}
                        value={input}
                    />
                    {
                        isInputFocused &&
                        <img 
                            src='/img/units/custom-add.png' width={24} height={24}
                            onClick={(e) => handleClickAdd(e)}
                        />
                    }
                </div>
                <div className='spacing-8' />
                <div className={customStyle.CustomSet__ExKeywords__KeywordsWrap}>
                    {
                        inputArr.map((keyword, idx) => {
                            return (
                                    <div key={idx} className={customStyle.CustomSet__ExKeywords__Keyword}>
                                        <p className='h7 fc-gray-700'>{keyword}</p>
                                        <img 
                                            src='/img/units/toast-close.png' width={24} height={24} 
                                            onClick={() => handleClickDelete(idx)}
                                        />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}


