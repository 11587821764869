import { useEffect, useState } from 'react';
import msgStyle from '../../style/messages.module.css';
import SegmentList from './SegmentList';
import SegmentSet from './SegmentSet';
import { listBodyInitObj } from '../../data/segmentData';
import { Calendar } from '../Units/Calendar';


const Segment = () => {
    const [isTabSelected, setIsTabSelected] = useState(0);
    const [segmentListData, setSegmentListData] = useState();
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    // const segmentListData = JSON.parse(sessionStorage.getItem('segmentListData'));

    const navigateTab = (idx) => {
        setIsTabSelected(idx);
    }

    const deleteTab = (e, idx) => {
        e.stopPropagation();
        let copy = {...segmentListData};
        copy.body.splice(idx, 1);
        copy = JSON.stringify(copy);
        sessionStorage.setItem('segmentListData', copy);
        setIsEditorOpen(false);
        navigateTab(0);
    }

    const updateBodyList = (bodyId, key, newVal) => {
        let newList = {...segmentListData};
        newList.body.map((list, idx) => {
            if (list.id === bodyId) {
                list[key] = newVal;
            }
        })
        setSegmentListData(newList);
        sessionStorage.setItem('segmentListData', JSON.stringify(newList));
    }

    const addBodyList = () => {
        const newList = {...segmentListData};
        const lastId = newList.body ? newList.body.length : 0;
        if (!newList.body) {
            newList['body'] = [listBodyInitObj];
        } else {
            newList.body.push({...listBodyInitObj, id: lastId});
        }
        setSegmentListData(newList);
        sessionStorage.setItem('segmentListData', JSON.stringify(segmentListData));
        setIsTabSelected(1);
        setIsEditorOpen(true);
        navigateTab(lastId+1);
    }

    // get segmentListData from sessionStorage
    useEffect(() => {
        let newList = JSON.parse(sessionStorage.getItem('segmentListData'));
        setSegmentListData(newList);
    }, [])

    return (
        <div className={msgStyle.Messages__Container}>
            <div className={msgStyle.Messages__Header}>
                <div
                    data-idx={0} 
                    className={isTabSelected === 0 ? msgStyle.Messages__Header__Tab__selected : msgStyle.Messages__Header__Tab}
                    onClick={() => setIsTabSelected(0)}
                >
                    <p className={`h6 ${isTabSelected === 0 ? 'fc-prim-800' : 'fc-gray-300'}`}>세그먼트 리스트</p>
                </div>
                {
                    isEditorOpen &&
                    <div
                        className={isTabSelected > 0 ? msgStyle.Messages__Header__Tab__selected : msgStyle.Messages__Header__Tab}
                        onClick={() => setIsTabSelected((1))}
                    >
                        <p className={`h6 ${isTabSelected > 0 ? 'fc-prim-800' : 'fc-gray-300'}`}>{`편집: '${segmentListData.body[segmentListData.body.length - 1].name}'`}</p>
                        <div className='spacing-8' />
                        <img 
                            src='/img/messages/messages-cancel.png' 
                            width={24} height={24} 
                            onClick={(e) => deleteTab(e, segmentListData.body.length - 1)}
                        />
                    </div>
                }
                <div className={msgStyle.Messages__Header__Blank} />
            </div>
            <div className='spacing-32' />
            {
                isTabSelected === 0 ?
                <SegmentList navigateTab={navigateTab} segmentListData={segmentListData} addBodyList={addBodyList} /> :
                <SegmentSet navigateTab={navigateTab} updateBodyList={updateBodyList} lastId={segmentListData.body ? segmentListData.body.length - 1 : 0} setIsEditorOpen={setIsEditorOpen}/>
            }
        </div>
    )
}

export default Segment