import styled from 'styled-components';
import loginStyle from '../style/login.module.css';
import unitStyle from '../style/units.module.css';
import { colorSet, colors } from '../style/colorVar';
import { useEffect, useState } from 'react';
import { accounts } from '../data/accounts';
import { useNavigate } from 'react-router-dom';
import { login, signup } from '../apis/api/auth/api';

const Login = (props) => {
    const navigate = useNavigate();

    const [inputId, setInputId] = useState('');
    const [inputPW, setInputPW] = useState('');

    const handleInputId = (e) => {
        setInputId(e.target.value);
        // console.log(e.target.value);
    };
    
    const handleInputPW = (e) => {
        setInputPW(e.target.value);
        // console.log(e.target.value);
    };

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            handleLogin(e);
        }
    }

    const handleLogin = (e) => {
        e.preventDefault();
        if (inputId === '') {alert('아이디를 입력하세요.')}
        else if (inputPW === '') {alert('비밀번호를 입력하세요.')}
        else {
            login(inputId, inputPW)
                .then(res => {
                    if(res.randomId === undefined) alert('아이디 또는 비밀번호를 다시 확인하세요.');
                    else {
                        sessionStorage.setItem('user', res.randomId)
                        navigate('/');
                    }
                })
                .catch(error => alert('아이디 또는 비밀번호를 다시 확인하세요.'))
        }
    };

    return (
        <div className={loginStyle.Login__Container}>
            <div className={loginStyle.Login__Left}>
                <div className='spacing-56' />
                <img src='/img/login/login-background.png' width={'90%'}/>
            </div>
            <div className={loginStyle.Login__Right}>
                <img src='/img/units/gentoo-symbol.png' width={56} height={56}/>
                <div className='spacing-24' />
                <p className='h4 fc-gray-700'>풀퍼널 고객 인게이지먼트 솔루션, 젠투</p>
                <div className='spacing-8' />
                <p className='h7 fc-gray-600'>대화부터 데이터 분석, 세그먼트까지</p>
                <div className='spacing-40' />
                <div className={loginStyle.Login__InputWrap}>
                    <input
                        className={loginStyle.Login__Input} 
                        name='login-id' 
                        type='text' 
                        onChange={(e) => handleInputId(e)} 
                        onKeyDown={(e) => {handleEnter(e)}}
                        placeholder='아이디'
                    ></input>
                </div>
                <div className='spacing-8' />
                <div className={loginStyle.Login__InputWrap}>
                    <input
                        className={loginStyle.Login__Input} 
                        name='login-pw' 
                        type='password' 
                        onChange={(e) => {handleInputPW(e)}} 
                        onKeyDown={(e) => {handleEnter(e)}}
                        placeholder='비밀번호'
                    ></input>
                </div>
                <div className='spacing-24' />
                <button 
                    className={(inputId && inputPW) ? loginStyle.Login__Button__active : loginStyle.Login__Button__inactive}
                    onClick={(e) => {handleLogin(e)}}
                >
                    <p className='h6 fc-gray-500'>로그인</p>
                </button>
                <div className='spacing-24' />
                <div className={loginStyle.Login__InputWrap}>
                    <p className='h7 fc-gray-500'>문의하기</p>
                </div>
                <div className={loginStyle.Login__InputWrap} style={{height: '1px'}}>
                    <div className={unitStyle.Break__Hr} />
                </div>
                <div className='spacing-16' />
                <p className='h7 fc-gray-500'>기업 회원이 아니신가요?</p>
                <div className='spacing-16' />
                <button
                    className={loginStyle.Login__DemoButton}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://demo.gentooai.com/register'
                    }}
                >
                    <p className='h6 fc-gray-800'>데모 체험으로 바로가기🚀</p>
                </button>
            </div>
        </div>
    )
};

export default Login