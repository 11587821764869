import styled from 'styled-components';
import { colorSet } from '../../style/colorVar';
import { getFeed, getFeedContent, getInsight, getPushMessages, postPushMessages } from '../../apis/api/kpis';
import { getFeedList, getInsightList } from '../../apis/services/kpis';
import { useEffect, useState } from 'react';
import { UserScatterChart } from '../Main/Charts';
import { ChartBox, Container, ContentsBox, FadeChartBox, FlexContainer, IconWrap, Spacer, TextDeco, fadeIn } from '../../style/Units';
import { getFormattedDate } from '../../apis/api/utils';
import { TopNav, TopNavDLST } from '../Main/TopNav';
import { Link, useNavigate } from 'react-router-dom';

export const Insight = () => {
    let thisMonday = new Date();
    const userId = sessionStorage.getItem('login');
    let [selectedDate, setSelectedDate] = useState(userId === 'demo' ? '2023-09-04' : thisMonday);
    let [selectedTableIdx, setSelectedTableIdx] = useState(-1);
    let [topHover, setTopHover] = useState(false);
    let [scatterDataVislble, setScatterDataVisible] = useState(false);
    let [selectedTableData, setSelectedTableData] = useState(-1);
    let [insightList, setInsightList] = useState({});
    let [selectedTab, setSelectedTab] = useState(0);
    const userNums = insightList.userNums;
    const coordinates = insightList.coordinates;
    const tableData = insightList.tableData;
    thisMonday.setDate(thisMonday.getDate() - thisMonday.getDay() + 1);
    
    // table item click event
    const handleTableClick = (e) => {
        setSelectedTableIdx(e.target.dataset.id);
    }

    // hover
    const handleTopMouseOver = () => {
        setTopHover(true);
    }

    const handleTopMouseOut = () => {
        setTopHover(false);
    }

    const handleScatterData = () => {
        setScatterDataVisible(true);
    }

    // characteristics dummy data
    useEffect(() => {
        const insightCall = (selectedDate) => {
            getInsight(selectedDate, userId)
                .then(getInsightList)
                .then((res) => setInsightList(res))
        }
        insightCall(selectedDate);
        setSelectedTableData(-1);
        setSelectedTableIdx(-1);
    }, [selectedDate]);


    return (
        <Container>
            {/* <TopNav page={"Insight"} background={"url(/img/menu/insight_FILL0_wght300_GRAD0_opsz24_scnd900.svg)"} setSelectedDate={setSelectedDate} /> */}
            <ChartBox $margin="8px 0 0 0">
                <Container $padding="0 12px">
                    <FlexContainer $alignItems="center" $justifyContent="flex-start" $margin="4px 0 0 0" >
                        <span style={{ width: "20px", height: "20px", background: colorSet.prim200, borderRadius: "5px", marginRight: "4px" }}></span>
                        <p style={{ marginRight: "8px" }}>추천 목적형 사용자</p>
                        <span style={{ width: "20px", height: "20px", background: colorSet.prim50, borderRadius: "5px", marginRight: "4px" }}></span>
                        <p style={{ marginRight: "8px" }}>정보 탐색형 사용자</p>
                        <span style={{ width: "20px", height: "20px", background: colorSet.scnd300, borderRadius: "5px", marginRight: "4px" }}></span>
                        <p style={{ marginRight: "8px" }}>이탈 사용자</p>
                    </FlexContainer>
                    <FlexContainer $justifyContent="flex-start" style={{ borderRadius: "5px", overflow: "hidden", margin: "8px 0" }}>
                        {
                            userNums?.totalUserNums === 0 ?
                                <div style={{ width: "100%", height: "64px", background: colorSet.scnd500, display: "flex", alignItems: "center", justifyContent: "center", color: colorSet.scnd50, fontSize: "32px" }}>N/A data</div> :
                                <>
                                    <div onMouseOver={handleTopMouseOver} style={{ width: userNums?.recommend + "%", height: "64px", background: colorSet.prim200, display: "flex", alignItems: "center", justifyContent: "center", color: colorSet.scnd50, fontSize: "32px", visibility: (userNums?.recommend <= '0' && "hidden") }}>{userNums?.recommend}%</div>
                                    <div onMouseOver={handleTopMouseOver} style={{ width: userNums?.information + "%", height: "64px", background: colorSet.prim50, display: "flex", alignItems: "center", justifyContent: "center", color: colorSet.scnd50, fontSize: "32px", visibility: (userNums?.information <= '0' && "hidden") }}>{userNums?.information}%</div>
                                    <div style={{ width: userNums?.potential + "%", height: "64px", background: colorSet.scnd300, display: "flex", alignItems: "center", justifyContent: "center", color: colorSet.scnd50, fontSize: "32px", visibility: (userNums?.potential <= '0' && "hidden") }}>{userNums?.potential}%</div>
                                </>
                        }
                    </FlexContainer>
                </Container>
            </ChartBox>
            <Spacer size={16} />
            <FlexContainer $justifyContent="flex-start" $margin="-1px 0">
                <div 
                    onClick={() => {setSelectedTab(0); setSelectedTableData(-1); setSelectedTableIdx(-1)}}
                    style={{width: "140px", borderRadius: "10px 10px 0 0", background: colorSet.scnd50, textAlign: "center", padding: "4px 0", transform: selectedTab === 0 ? null : "scale(0.95, 0.9)", cursor: "pointer"}}
                >
                    유효 사용자 특성
                </div>
                <div
                    onClick={() => {setSelectedTab(1); setSelectedTableData(-1); setSelectedTableIdx(-1)}}
                    style={{width: "140px", borderRadius: "10px 10px 0 0", background: colorSet.scnd50, textAlign: "center", padding: "4px 0", transform: selectedTab === 1 ? null : "scale(0.95, 0.9)", cursor: "pointer"}}
                >
                    이탈 사용자 특성
                </div>
            </FlexContainer>
            <ChartBox $borderRadius="0 5px 5px 5px" $padding="16px 20px">
                {
                    selectedTab === 0 ?
                    <>
                        <ChartBox $background={colorSet.scnd100} $padding="16px" $boxShadow="none">💡 각 사분면 영역을 클릭하시면 사용자 행동 유형별 대화 키워드 분석 결과를 확인하실 수 있어요</ChartBox>
                        <UserScatterChart coordinates={coordinates} setSelectedTableData={setSelectedTableData} />
                    </> :
                    <FlexContainer $height="550px" style={{fontSize: "32px", fontWeight: "700", color: colorSet.scnd300}}>준비 중인 기능이에요!</FlexContainer>
                }
            </ChartBox>
            <Spacer size={16} />
            {
                selectedTableData < 0 ?
                    null :
                    <FadeChartBox>
                        <FlexContainer $alignItems="flex-start" $justifyContent="space-between">
                            <STable>
                                <thead>
                                    <tr>
                                        <th>영역</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <STd $background={colorSet.prim50}>{tableData[selectedTableData]?.title}</STd>
                                    </tr>
                                </tbody>
                            </STable>
                            <STable>
                                <thead>
                                    <tr>
                                        <th>상위 엔티티</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData[selectedTableData]?.contents.map((data, i) =>
                                            <tr key={i}>
                                                <STd $background={+selectedTableIdx === i ? colorSet.prim50 : ''} data-id={i} onClick={(e) => { handleTableClick(e) }}>{data.entity}</STd>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </STable>
                            <STable>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>상위 키워드</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.entries(tableData[selectedTableData]?.contents[selectedTableIdx]?.features || { '': '' })?.map((data, idx) =>{
                                            return <tr className='keywords' key={idx}>
                                                        <td>{data[1].keyword}</td>
                                                        <td>{data[1].count}</td>
                                                    </tr>
                                        }
                                        )
                                    }
                                </tbody>
                            </STable>
                        </FlexContainer>
                    </FadeChartBox>
            }
        </Container>
    )
};

export const InsightDLST = () => {
    let thisMonday = new Date();
    const userId = sessionStorage.getItem('login');
    const navigate = useNavigate();

    let [selectedDate, setSelectedDate] = useState(userId === 'demo' ? '2023-09-04' : thisMonday);
    let [selectedTableIdx, setSelectedTableIdx] = useState(-1);
    let [selectedTableData, setSelectedTableData] = useState(-1);
    let [insightList, setInsightList] = useState({});
    let [selectedTab, setSelectedTab] = useState(0);
    let [feedList, setFeedList] = useState([]);
    let [isClickFeed, setIsClickFeed] = useState(false);
    let [clickedMsg, setClickedMsg] = useState('');
    let [feedContent, setFeedContent] = useState([]);
    
    const userNums = insightList.userNums;
    const coordinates = insightList.coordinates;
    const tableData = insightList.tableData;
    thisMonday.setDate(thisMonday.getDate() - thisMonday.getDay() + 1);
    
    // feed icon match
    const colorMatch = (elem) => {
        let matched;
        switch (elem) {
            case 'subject':
                matched = colorSet.prim100;
                break;
            case 'keyword':
                matched = "thistle";
                break;
            case 'click':
                matched = "lightsteelblue";
                break;
            case 'trend':
                matched = "lightblue";
                break;
        }
        return matched
    };

    const handleClickFeed = (e) => {
        const contents = JSON.parse(e.currentTarget.dataset.feed);
        setIsClickFeed(contents.feedId);
        setClickedMsg({'feedId': contents.feedId, 'feedType': contents.type, 'feed': contents.text});
    }

    const handleGenMsg = (e) => {
        let prev = new Array();
        let feed = e.currentTarget.dataset.feed;
        feed = JSON.parse(feed);

        postPushMessages(feed)
            .then((res) => {
                if (!res) {
                    alert('이미 메세지가 생성된 피드입니다. 메세지 탭에서 확인하시겠습니까?')
                }
                window.location.replace('/dlst/messages')
            })
    }

    // characteristics dummy data
    useEffect(() => {
        const insightCall = (selectedDate) => {
            getInsight(selectedDate, userId)
                .then(getInsightList)
                .then((res) => setInsightList(res))
        }
        insightCall(selectedDate);
        setSelectedTableData(-1);
        setSelectedTableIdx(-1);
    }, [selectedDate]);

    useEffect(() => {
        const feedCall = () => {
            getFeed()
                .then(getFeedList)
                .then((res) => setFeedList(res));
        };
        feedCall();
    }, []);

    useEffect(() => {
        const feedContentCall = () => {
            getFeedContent(clickedMsg?.feedId, clickedMsg?.feedType, clickedMsg?.feed)
                .then((res) => setFeedContent(res));
        }
        if (clickedMsg === '') return;
        feedContentCall();
    }, [clickedMsg]);
    

    return (
        <FlexContainer $flexDir={"column"} $flexWrap={"nowrap"} $height={"calc(100vh - 16px)"} $alignItems={"flex-start"} style={{overflowY: 'hidden'}}>
            <TopNavDLST page={"Insight"} background={"url(/img/menu/insight_FILL0_wght300_GRAD0_opsz24_scnd900.svg)"} setSelectedDate={setSelectedDate} />
            <Spacer size={8} />
            <FlexContainer>
                <ChartBox 
                    $padding="8px 24px"
                    $width= {isClickFeed ? "40%" : "100%"}
                    $height={"calc(100vh - 64px)"}
                    style={{overflowY: "scroll"}}
                >
                    <Container $maxWidth="680px" $margin="auto">
                        <TextDeco $fontSize={"12px"} $fontWeight="600" $margin="16px 0">{feedList[0]?.date}</TextDeco>
                        {
                            feedList[0]?.feedArr.map((data, idx) => {
                                return (
                                    <div key={idx}>
                                        <FlexContainer $justifyContent={"space-between"} $alignItems={"flex-start"} $flexWrap="no-wrap">
                                            <FlexContainer $justifyContent={"flex-start"} $alignItems={"flex-start"} $flexWrap="no-wrap">
                                                <IconWrap $background={colorMatch(data.type)} $margin="4px 0 0 0">
                                                    <img src={`/img/insight/${data.type}.svg`} width='22px' />
                                                </IconWrap>
                                                <Spacer size={16} />
                                                <TextDeco 
                                                    onClick={(e) => {handleClickFeed(e)}}
                                                    data-feed={JSON.stringify({'feedId': data.feedId, 'type': data.type, 'text': data.text})}
                                                    $fontSize={isClickFeed ? "14px" : "16px"} 
                                                    $fontWeight="800" 
                                                    $margin="0px 0 0 0"
                                                    style={{cursor: 'pointer', color: (isClickFeed === data.feedId && colorSet.prim700)}}
                                                    dangerouslySetInnerHTML={{__html: data.text}}
                                                ></TextDeco>
                                            </FlexContainer>
                                            <Spacer size={40} />
                                            {
                                                isClickFeed ?
                                                <MsgIcon 
                                                    src="/img/insight/msg.svg" 
                                                    onClick={(e) => {handleGenMsg(e)}}
                                                    data-feed={JSON.stringify({'feedId': data.feedId, 'type': data.type, 'text': data.text})}
                                                    width="24px" 
                                                /> :
                                                <MsgBtn 
                                                    onClick={(e) => {handleGenMsg(e)}}
                                                    data-feed={JSON.stringify({'feedId': data.feedId, 'type': data.type, 'text': data.text})}
                                                    $margin="4px 0 0 0"
                                                >
                                                    메세지 만들기
                                                </MsgBtn>
                                            }
                                        </FlexContainer>
                                        <Spacer size={40} />
                                    </div>
                                )
                            })
                        }
                    </Container>
                </ChartBox>
                <ChartBox 
                    $padding={isClickFeed ? "8px 24px" : "0"}
                    $margin={isClickFeed ? "0 0 0 1%" : "0"}
                    $width= {isClickFeed ? "59%" : "0%"}
                    $height={"calc(100vh - 64px)"}
                    style={{overflowY: "scroll"}}
                >
                    { 
                        isClickFeed ?
                        <>
                            <FlexContainer $justifyContent={"flex-end"}>
                                <img 
                                    onClick={() => {setIsClickFeed(false)}}
                                    style={{cursor: 'pointer'}}
                                    src='/img/insight/close.svg' width={isClickFeed ? '24px' : 0}
                                />
                            </FlexContainer>
                            <Spacer size={26} />
                            <Container>
                                <TextDeco $fontSize="20px" $fontWeight="800" dangerouslySetInnerHTML={{__html: clickedMsg.feed}}></TextDeco>
                                <Spacer size={4} />
                                <TextDeco>클릭한 인사이트와 관련된 주요 대화 내용을 확인해보세요</TextDeco>
                            </Container>
                            <Spacer size={16} />
                            {
                                feedContent?.map((content, idx) => {
                                    return (
                                        <>
                                            <ContentsBox $key={idx} $padding={"8px 16px"}>
                                                {
                                                    content?.map((data, idx) => {
                                                        return (
                                                            <>
                                                                <FlexContainer key={idx} $justifyContent="flex-start" $alignItems="flex-start" $flexWrap="no-wrap" $padding="8px 0">
                                                                    <IconWrap $width="16px" $height="16px" $background={data.role === 'user' ? colorSet.prim100 : colorSet.prim300} />
                                                                    <Spacer size={8} />
                                                                    <TextDeco $fontSize="14px">{data.content}</TextDeco>
                                                                </FlexContainer>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ContentsBox>
                                            <Spacer size={16} />
                                        </>
                                    )
                                })
                            }
                        </> :
                        null
                    }
                </ChartBox>
            </FlexContainer>
        </FlexContainer>
    )
};

const STable = styled.table`
    width: 33%;
    border-collapse: collapse;
    border: 1px solid ${colorSet.scnd500};

    th {
        background: ${colorSet.scnd300}
    }

    th, td {
        text-align: left;
        padding: 4px 16px;
    }

    .keywords {
        opacity: 0;
        animation: ${fadeIn} 0.1s ease-in-out forwards;
    }
`

const STd = styled.td`
    background: ${props => props.$background || ''};
`

const MsgBtn = styled.button`
    width: 120px;
    height: 30px;
    margin: ${props => props.$margin || 0};
    border: none;
    border-radius: 10px;
    background-color: #5B82DB;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
`

const MsgIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`