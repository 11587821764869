import { useState } from 'react';
import agentStyle from '../../style/agent.module.css';
import unitsStyle from '../../style/units.module.css';
import { TableLabel } from '../Units/Label';

export const BotList = (props) => {
    const {email} = props;
    const [isLinkClicked, setIsLinkClicked] = useState(false);
    const tableData = {
        head: [
            '이름',
            '상태',
            '프로필',
            'ID',
            '바로가기',
            '통계',
            '링크'
        ],
        body: [
            {
                name: '젠투',
                status: <TableLabel status={'active'} />,
                profile: '/img/units/gentoo-symbol.png',
                id: '1868',
                shortcut: `${process.env.REACT_APP_CHAT_URL}/test/chat/${encodeURIComponent(email)}`,
                analytics: '/',
                link: `${process.env.REACT_APP_CHAT_URL}/test/chat/${encodeURIComponent(email)}`,
            }
        ]
    }

    const handleClickShortcut = () => {
        window.location.href = tableData.body[0].link;
    }

    const handleClickCopy = () => {
        const input = document.getElementById('chat-link');
        input.select();
        document.execCommand('copy');
    }

    return (
        <>
            <div className={agentStyle.AgentList__Contents}>
                <div className={agentStyle.AgentList__Table__Wrap}>
                    <table className={agentStyle.AgentList__Table}>
                        <thead>
                            <tr>
                                {
                                    tableData.head.map((th, idx) => {
                                        return (
                                            <th
                                                className={agentStyle.AgentList__Table__Head}
                                                style={{ wordBreak: 'keep-all' }}
                                            >
                                                <p className='h6'>{th}</p>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    <p className='h7'>{tableData.body[0].name}</p>
                                </td>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    {tableData.body[0].status}
                                </td>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    <img src={tableData.body[0].profile} width={32} height={32} />
                                </td>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    <p className='h7'>{tableData.body[0].id}</p>
                                </td>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    <img
                                        src='/img/units/list-shortcut.png' width={32} height={32}
                                        onClick={() => handleClickShortcut()}
                                    />
                                </td>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    <img src='/img/units/list-analytics.png' width={32} height={32} />
                                </td>
                                <td className={agentStyle.AgentList__Table__Body}>
                                    <img
                                        src='/img/units/list-link.png' width={32} height={32}
                                        onClick={() => setIsLinkClicked(true)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {
                isLinkClicked ?
                    <div className={agentStyle.AgentList__Dimmed}>
                        <div className={agentStyle.AgentList__Link__Wrap}>
                            <div className={agentStyle.AgentList__Link__Title}>
                                <div className={agentStyle.AgentList__Link__Title__left}>
                                    <img src='/img/units/list-share.png' width={24} height={24} />
                                    <div className='spacing-8' />
                                    <p className='h4'>링크</p>
                                </div>
                                <img
                                    src='/img/units/toast-close.png' width={36} height={36}
                                    onClick={() => setIsLinkClicked(false)}
                                />
                            </div>
                            <div className='spacing-32' />
                            <div className={agentStyle.AgentList__Link__Content}>
                                <input
                                    id='chat-link'
                                    type='text' value={tableData.body[0].link} readOnly={true}
                                    className={unitsStyle.CustomSet__TextInput}
                                />
                                <button
                                    className={agentStyle.AgentList__Link__Content__Button}
                                    onClick={() => handleClickCopy()}
                                >
                                    <p className='h6 fc-white'>복사</p>
                                </button>
                            </div>
                        </div>
                    </div> :
                    null
            }
        </>
    )
}