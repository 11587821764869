export const colors = {
    "main": "#008eff",
    "pointBlue": "#005ad6",
    "pointGreen": "#24cc93",
    "backLight": "#e4f1ff",
    "backDark": "#e0e4ec",
    "base": "#ffffff",
    "fontGray": "#777",
}

export const colorSet = {
    "main": "#154CCB",
    "prim900": "#154CCB",
    "prim700": "#5263D3",
    "prim500": "#767BDB",
    "prim300": "#9494E3",
    "prim200": "#B1ADEA",
    "prim100": "#CBC8F1",
    "prim50": "#E5E3F8",

    "scnd900": "#202020",
    "scnd700": "#434343",
    "scnd500": "#7B7B7B",
    "scnd300": "#D9D9D9",
    "scnd100": "#F5F5F5",
    "scnd50": "#FFF",
}