import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Label, LabelList, Legend, Line, LineChart, Pie, PieChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import styled from 'styled-components';
import { colorSet, colors } from '../../style/colorVar';
import { getFormattedDate } from '../../apis/api/utils';
import { useState } from 'react';

export const Chart1 = (props) => {
    const WAUList = props.WAUList;

    const WAUdata = [
        {
            "date": "11주 전",
            "WAU": WAUList?.WAUdata[0],
        },
        {
            "date": "10주 전",
            "WAU": WAUList?.WAUdata[1],
        },
        {
            "date": "9주 전",
            "WAU": WAUList?.WAUdata[2],
        },
        {
            "date": "8주 전",
            "WAU": WAUList?.WAUdata[3],
        },
        {
            "date": "7주 전",
            "WAU": WAUList?.WAUdata[0],
        },
        {
            "date": "6주 전",
            "WAU": WAUList?.WAUdata[1],
        },
        {
            "date": "5주 전",
            "WAU": WAUList?.WAUdata[2],
        },
        {
            "date": "4주 전",
            "WAU": WAUList?.WAUdata[3],
        },
        {
            "date": "3주 전",
            "WAU": WAUList?.WAUdata[0],
        },
        {
            "date": "2주 전",
            "WAU": WAUList?.WAUdata[1],
        },
        {
            "date": "1주 전",
            "WAU": WAUList?.WAUdata[2],
        },
        {
            "date": "이번주",
            "WAU": WAUList?.WAUdata[3],
        }
    ];

    return (
        <ChartWrap $width="auto" $height="246px">
            <ResponsiveContainer width="99.9%">
                <BarChart
                    width="100%"
                    height={246}
                    data={WAUdata}
                >
                    <XAxis
                        dataKey="date"
                        interval={0}
                        fontSize={"10px"}
                        stroke='#555'
                        fontWeight={900}
                    />
                    <YAxis
                        width={20}
                        axisLine={true}
                        tickLine={false}
                        fontSize={"10px"}
                        stroke='#555'
                        fontWeight={900}
                    />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                        dataKey="WAU"
                        fill={colorSet.prim300}
                        radius={"10"}
                    />
                </BarChart>
            </ResponsiveContainer>
        </ChartWrap>
    )
}

export const Chart2 = (props) => {
    const clickRateList = props.clickRateList;

    const clickRateData = [
        {
            name: '3주 전',
            "클릭전환율[%]": clickRateList?.clickRate[0],
            추천제공수: clickRateList?.recommendCount[0],
            amt: 1400,
        },
        {
            name: '2주 전',
            "클릭전환율[%]": clickRateList?.clickRate[1],
            추천제공수: clickRateList?.recommendCount[1],
            amt: 1506,
        },
        {
            name: '1주 전',
            "클릭전환율[%]": clickRateList?.clickRate[2],
            추천제공수: clickRateList?.recommendCount[2],
            amt: 989,
        },
        {
            name: '이번주',
            "클릭전환율[%]": clickRateList?.clickRate[3],
            추천제공수: clickRateList?.recommendCount[3],
            amt: 1228,
        },
    ];

    return (
        <ChartWrap $width="auto" $padding="16px">
            <ResponsiveContainer>
                <ComposedChart
                    width={350}
                    height={200}
                    data={clickRateData}
                >
                    <XAxis
                        dataKey="name"
                        stroke='#555'
                        fontSize={"10px"}
                        fontWeight={900}
                    />
                    <YAxis
                        stroke='#555'
                        axisLine={false}
                        tickLine={false}
                        fontSize={"10px"}
                        fontWeight={900}
                    />
                    <Tooltip />
                    {/* <Legend /> */}
                    {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                    <Bar dataKey="추천제공수" barSize={30} fill={colors.main} />
                    <Line type="monotone" dataKey="클릭전환율[%]" stroke={colors.pointGreen} />
                </ComposedChart>
            </ResponsiveContainer>
        </ChartWrap>
    )
};

export const Chart3 = (props) => {
    let prodRateList = props.prodRateList;

    const prodRateData = {
        data: [
            { name: 'Group A', value: prodRateList?.impression },
            { name: 'Group B', value: (prodRateList?.total - prodRateList?.impression) },
        ],

        COLORS: ['#0088FE', '#eee'],

        RADIAN: Math.PI / 180,

    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * prodRateData.RADIAN);
        const y = cy + radius * Math.sin(-midAngle * prodRateData.RADIAN);

        return (
            <text x={x} y={y} fill="#eee" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={"1rem"}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="70%">
            <PieChart width={"100%"} height={"100%"}>
                <Pie
                    data={prodRateData.data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={"100%"}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {prodRateData.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={prodRateData.COLORS[index % prodRateData.COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
};

export const FunnelChart = (props) => {
    const WAUList = props.WAUList;
    const data = [
        {
            name: '전체 대화 수',
            ref: 0,
            cur: WAUList[0]?.total,
        },
        {
            name: '유효 대화 수',
            ref: WAUList[0]?.total - WAUList[0]?.served,
            cur: WAUList[0]?.served,
        },
        {
            name: '상품 클릭 수',
            ref: WAUList[0]?.served - WAUList[0]?.clicked,
            cur: WAUList[0]?.clicked,
        },
    ];

    const cvRate = [
        {
            name: '유효 대화 수',
            value: WAUList[0]?.servedRate,
        },
        {
            name: '상품 클릭 수',
            value: WAUList[0]?.clickRate,
        }
    ]

    const getIntroOfPage = (name, payload) => {
        // console.log('payload, ', payload);
        if (name === '전체 대화 수') {
            return;
        }
        if (name === '유효 대화 수') {
            return `유효 대화 전환율: ${cvRate[0]?.value}%`;
        }
        if (name === '상품 클릭 수') {
            return `상품 클릭 전환율: ${cvRate[1]?.value}%`;
        }
        return '';
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <TooltipContainer className="custom-tooltip">
                    <p className="label">{`${label} : ${payload[0].value}`}</p>
                    <p className="intro">{getIntroOfPage(label, payload)}</p>
                    {/* <p className="desc">Anything you want can be displayed here.</p> */}
                </TooltipContainer>
            );
        }


        return null;

    };

    return (
        <ResponsiveContainer width="99%" height="99%">
            <BarChart
                width={500}
                height={324}
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" hide={true} />
                <YAxis axisLine={{ strokeWidth: 0.2 }} fontSize={14} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="cur" stackId="a" fill={colorSet.prim700} />
                <Bar dataKey="ref" stackId="a" fill={colorSet.prim50} />
            </BarChart>
        </ResponsiveContainer>
    )
}

export const ClickRateChart = (props) => {
    const WAUList = props.WAUList;

    return (
        <ResponsiveContainer width="99%" height="99%">
            <ComposedChart
                width={500}
                height={400}
                data={WAUList}
            >
                <CartesianGrid stroke="#f5f5f5" vertical={false} />
                <XAxis dataKey="week" fontSize={13} reversed={true} />
                <YAxis yAxisId="left" orientation="left" fontSize={13} >
                    <Label 
                        angle={-90} 
                        value='사용자 수' 
                        position='insideLeft' 
                        style={{textAnchor: 'middle'}} 
                    />
                </YAxis>
                <YAxis yAxisId="right" orientation="right" fontSize={14} >
                    <Label angle={-90} value='클릭 전환율(%)' position='insideRight' style={{textAnchor: 'middle'}} />
                </YAxis>
                <Tooltip />
                <Legend iconSize={14} wrapperStyle={{ fontSize: "12px", paddingTop: "12px" }} />
                <Bar yAxisId="left" name='사용자 수' dataKey="wau" barSize={40} fill={colorSet.prim500} />
                <Line yAxisId="right" name='클릭 전환율(%)' type="monotone" dataKey="clickRate" stroke={colorSet.main} strokeWidth={3} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export const UserScatterChart = (props) => {
    const setSelectedTableData = props.setSelectedTableData;
    const coordinates = props.coordinates;

    // anchor dots for fixed chart size
    const anchorData = [
        {x: 100, y: 8},
        {x: 100, y: 0},
        {x: 0, y: 8},
        {x: 0, y: 0},
    ]

    // chart hover event
    let [selectedArea, setSelectedArea] = useState(-1);
    const handleChartMouseOver = (e) => {
        setSelectedArea(e.target.id);
    }

    const handleChartMouseOut = () => {
        setSelectedArea(-1);
    }

    const handleChartClick = (e) => {
        const selected = e.target.id;

        switch(selected) {
            case 'scatterArea1':
                setSelectedTableData(0);
                break;
            case 'scatterArea2':
                setSelectedTableData(1);
                break;
            case 'scatterArea3':
                setSelectedTableData(2);
                break;
            case 'scatterArea4':
                setSelectedTableData(3);
                break;
            default:
                break;
        }
    }

    return (
        <ResponsiveContainer width="100%" height={550}>
            <ScatterChart
                width={600}
                height={20}
                margin={{
                    top: 20,
                    right: 28,
                    bottom: 20,
                    left: 8
                }}
            >
                {/* <CartesianGrid vertical={false} strokeOpacity={0.3}/> */}
                <XAxis type="number" dataKey="x" name="구체성" unit="" hide={false}/>
                <YAxis type="number" dataKey="y" name="발화 수" unit="회" hide={false} />
                <ZAxis range={[200, 201]} />
                {/* <Tooltip cursor={{ strokeDasharray: "3 3" }} /> */}
                <Scatter name="recommend" data={coordinates?.recommend} fill={colorSet.prim300} isAnimationActive={true}>
                </Scatter>
                <Scatter name="information" data={coordinates?.information} fill={colorSet.prim50} isAnimationActive={true}>
                </Scatter>
                <Scatter name="anchor" data={anchorData} shape={null} />
                <ReferenceLine y={4} stroke={colorSet.scnd300} strokeWidth={1} label={{ value: "구체성", position: "insideTopRight" }} />
                <ReferenceLine x={50} stroke={colorSet.scnd300} strokeWidth={1} label={{ value: "발화 수", position: "insideTopLeft" }} />
                <ReferenceArea
                    id='scatterArea1'
                    x1={50}
                    x2={100}
                    y1={4}
                    y2={8}
                    ifOverflow='hidden'
                    fill={colorSet.scnd500}
                    fillOpacity={selectedArea === 'scatterArea1' ? 0.5 : 0}
                    onMouseOver={(e) => {handleChartMouseOver(e)}}
                    onMouseOut={handleChartMouseOut}
                    onClick={(e) => {handleChartClick(e)}}
                    // label={selectedArea === 'scatterArea1' ? { value: "대화 키워드 분석", fill: 'white', fontSize: "32px", position: 'insideTopRight' } : {}}
                />
                <ReferenceArea
                    id='scatterArea2'
                    x1={50}
                    x2={0}
                    y1={4}
                    y2={8}
                    ifOverflow='hidden'
                    fill={colorSet.scnd500}
                    fillOpacity={selectedArea === 'scatterArea2' ? 0.5 : 0}
                    onMouseOver={(e) => {handleChartMouseOver(e)}}
                    onMouseOut={handleChartMouseOut}
                    onClick={(e) => {handleChartClick(e)}}
                    // label={selectedArea === 'scatterArea2' ? { value: "대화 키워드 분석", fill: 'white', fontSize: "32px", position: 'insideTopRight' } : {}}
                />
                <ReferenceArea
                    id='scatterArea3'
                    x1={50}
                    x2={0}
                    y1={4}
                    y2={0}
                    ifOverflow='hidden'
                    fill={colorSet.scnd500}
                    fillOpacity={selectedArea === 'scatterArea3' ? 0.5 : 0}
                    onMouseOver={(e) => {handleChartMouseOver(e)}}
                    onMouseOut={handleChartMouseOut}
                    onClick={(e) => {handleChartClick(e)}}
                    // label={selectedArea === 'scatterArea3' ? { value: "대화 키워드 분석", fill: 'white', fontSize: "32px", position: 'insideTopRight' } : {}}
                />
                <ReferenceArea
                    id='scatterArea4'
                    x1={50}
                    x2={100}
                    y1={4}
                    y2={0}
                    ifOverflow='hidden'
                    fill={colorSet.scnd500}
                    fillOpacity={selectedArea === 'scatterArea4' ? 0.5 : 0}
                    onMouseOver={(e) => {handleChartMouseOver(e)}}
                    onMouseOut={handleChartMouseOut}
                    onClick={(e) => {handleChartClick(e)}}
                    // label={selectedArea === 'scatterArea4' ? { value: "대화 키워드 분석", fill: 'white', fontSize: "32px", position: 'insideTopRight' } : {}}
                />
            </ScatterChart>
        </ResponsiveContainer>
    )
}

export const FreqLineChart = (props) => {
    const freqData = [
        {
            date: '00-00',
            '빈도수': 0, 
        },
        {
            date: '00-00',
            '빈도수': 0, 
        },
        {
            date: '00-00',
            '빈도수': 0, 
        },
        {
            date: '00-00',
            '빈도수': 0, 
        },
        {
            date: '00-00',
            '빈도수': 0, 
        },
        {
            date: '00-00',
            '빈도수': 0, 
        },
        {
            date: '00-00',
            '빈도수': 0, 
        },
    ];
    const freqObj = props.freqObj || freqData;

    return (
        <ChartWrap $width="auto" $padding="16px">
            <ResponsiveContainer>
                <LineChart
                    width={350}
                    height={200}
                    data={freqObj}
                >
                    <XAxis
                        dataKey="date"
                        stroke='#555'
                        fontSize={"10px"}
                        fontWeight={900}
                    />
                    <YAxis
                        stroke='#555'
                        axisLine={false}
                        tickLine={false}
                        fontSize={"10px"}
                        fontWeight={900}
                    >
                    </YAxis>
                    <Tooltip />
                    {/* <Legend /> */}
                    {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                    <Line type="monotone" dataKey="빈도수" isAnimationActive={false} stroke={colorSet.prim700} fill={colorSet.prim700} />
                </LineChart>
            </ResponsiveContainer>
        </ChartWrap>
    )
};

const ChartWrap = styled.div`
    width: ${props => props.$width || "100%"};
    height: ${props => props.$height || "100%"};
    padding-top: ${props => props.$padding || "0"};
    margin: 0;
    border-radius: 10px;
    // background: ${colors.backLight};
    display: flex;
    flex-direction: ${props => props.$flexDirection || "row"};
    align-items: center;
    justify-content: center;
`

const TooltipContainer = styled.div`
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 4px;
    border-radius: 5px;
`