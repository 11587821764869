import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import anlStyle from '../../style/analytics.module.css';
import { useEffect, useState } from 'react';
import { getRate } from '../../apis/api/analytics/kpis';
import { convertPieData } from '../../apis/api/analytics/utils';

export const TopCharts = (props) => {
    const {from, to} = props;
    const [clickedCell, setClickedCell] = useState('unselected');
    const userId = sessionStorage.getItem('user');
    const [rateData, setRateData] = useState();
    const [clickedEntity, setClickedEntity] = useState('');

    const ENTITY_COLORS = ['#103b9c', '#154cca', '#0025E4', '#49ade5', '#bbc8e5'];
    const KEYWORDS_COLORS = ['#3c1490', '#6225c6', '#ae95d8', '#8c7dc5', '#cabcd4'];
    const SELECTED_COLORS = ['#1c89a1', '#6796a6', '#43d9be', '#346e82', '#004b68'];
    
    const entityPieData = {
        colors: ENTITY_COLORS,
        data: [
            { 
                name: '주종', 
                value: 332,
                selected: [
                    { name: '위스키', value: 332 },
                    { name: '와인', value: 275 },
                    { name: '버번 위스키', value: 120 },
                    { name: '일본 위스키', value: 80 },
                    { name: '기타', value: 193 },
                ],
            },
            { 
                name: '맛', 
                value: 275,
                selected: [
                    { name: '달달한', value: 352 },
                    { name: '달콤한', value: 325 },
                    { name: '플로럴', value: 100 },
                    { name: '달지 않은', value: 50 },
                    { name: '기타', value: 93 },
                ],
            },
            { 
                name: '상황',
                value: 120,
                selected: [
                    { name: '생일', value: 312 },
                    { name: '피크닉', value: 175 },
                    { name: '파티', value: 90 },
                    { name: '선물', value: 80 },
                    { name: '기타', value: 53 },
                ],
            },
            { 
                name: '가격', 
                value: 80,
                selected: [
                    { name: '위스키', value: 332 },
                    { name: '와인', value: 275 },
                    { name: '버번 위스키', value: 120 },
                    { name: '일본 위스키', value: 80 },
                    { name: '기타', value: 193 },
                ],
            },
            { 
                name: '기타', 
                value: 193,
                selected: [
                    { name: '위스키', value: 332 },
                    { name: '와인', value: 275 },
                    { name: '버번 위스키', value: 120 },
                    { name: '일본 위스키', value: 80 },
                    { name: '기타', value: 193 },
                ],
            },
        ]
    };

    const keywordPieData = {
        colors: KEYWORDS_COLORS,
        data: [
            { name: '위스키', value: 332 },
            { name: '와인', value: 275 },
            { name: '버번 위스키', value: 120 },
            { name: '부드러운', value: 80 },
            { name: '기타', value: 193 },
        ]
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = (props, data, type) => {  
        const {cx, cy, midAngle, innerRadius, outerRadius, percent, index} = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <>
                <text 
                    x={x} y={y} fill="white" 
                    textAnchor={'middle'} 
                    dominantBaseline="central"
                    className='h14'
                    onClick={() => {
                        if (type === 'entity') handleClickCell(data[index].name, index)
                    }}
                    onBlur={() => {
                        setClickedCell('unselected')
                        setClickedEntity('')
                    }}
                >
                    <tspan 
                        x={x} 
                        className={type === 'entity' && 'pointer'}
                    >
                        {data[index].name}
                    </tspan>
                    <tspan 
                        x={x} dy="1em" 
                        className={type === 'entity' && 'pointer'}
                    >
                        {(percent * 100).toFixed(0)}%
                    </tspan>
                </text>
            </>
            
        );
    };

    const handleClickCell = (name, index) => {
        setClickedCell(index);
        setClickedEntity(name);
    }

    useEffect(() => {
        getRate(userId, from, to, clickedEntity)
            .then(res => convertPieData(res))
            .then(res => setRateData(res)); 
    }, [clickedEntity, clickedCell, from])

    return (
        <>
            <p className='h4 fc-gray-700'>🚀 기간별 상위 엔티티 & 키워드</p>
            <div className='spacing-4' />
            <p className='h7 fc-gray-600'>설정한 기간 동안의 사용자 대화를 분석한 그래프로 상위 엔티티와 키워드의 비율을 한 눈에 비교할 수 있어 대화의 트렌드를 쉽게 파악할 수 있습니다.</p>
            <div className='spacing-16' />
            <hr className={anlStyle.Analytics__BreakLine} />
            <div className='spacing-32' />
            <div className={anlStyle.Analytics__TopChart__PieWrap}>
                {
                    rateData?.entity.length > 0 ?
                    <PieChart width={800} height={400}>
                        {
                            clickedCell !== 'unselected' ?
                            <>
                                <Pie
                                    data={rateData?.entity}
                                    cx={200}
                                    cy={200}
                                    innerRadius={72}
                                    outerRadius={143}
                                    labelLine={false}
                                    label={(props) => renderCustomizedLabel(props, rateData.entity, 'entity')}
                                    fill="#8884d8"
                                    paddingAngle={1}
                                    dataKey="value"
                                    isAnimationActive={false}
                                >
                                    <Label value='엔티티' position='center' fill='#444' />
                                    {rateData.entity.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={clickedCell === index ? entityPieData.colors[index % entityPieData.colors.length] : '#e1e1e1'} 
                                            onClick={() => handleClickCell(entry.name, index)}
                                            onBlur={() => {
                                                setClickedCell('unselected')
                                                setClickedEntity('')
                                            }}
                                        />
                                    ))}
                                </Pie>
                                <Pie
                                    data={rateData?.keyword}
                                    cx={600}
                                    cy={200}
                                    innerRadius={72}
                                    outerRadius={143}
                                    labelLine={false}
                                    label={(props) => renderCustomizedLabel(props, keywordPieData.data, 'keyword')}
                                    fill="#8884d8"
                                    paddingAngle={1}
                                    dataKey="value"
                                    isAnimationActive={false}
                                >
                                    <Label value={rateData?.entity[clickedCell].name} position='center' fill='#444' />
                                    {rateData?.keyword.map((entry, index) => (
                                    <Cell 
                                        key={`cell-${index}`} 
                                        fill={SELECTED_COLORS[index % SELECTED_COLORS.length]} 
                                        style={{outline: 'none'}}
                                    />
                                    ))}
                                </Pie> 
                            </> :
                            <>
                                <Pie
                                    data={rateData?.entity}
                                    cx={200}
                                    cy={200}
                                    innerRadius={72}
                                    outerRadius={143}
                                    labelLine={false}
                                    label={(props) => renderCustomizedLabel(props, rateData?.entity, 'entity')}
                                    fill="#8884d8"
                                    paddingAngle={1}
                                    dataKey="value"
                                    isAnimationActive={false}
                                >
                                    <Label value='엔티티' position='center' fill='#444' />
                                    {rateData?.entity.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={entityPieData.colors[index % entityPieData.colors.length]} 
                                            onClick={() => handleClickCell(entry.name, index)}
                                            className='pointer'
                                        />
                                    ))}
                                </Pie>
                                <Pie
                                    data={rateData?.keyword}
                                    cx={600}
                                    cy={200}
                                    innerRadius={72}
                                    outerRadius={143}
                                    labelLine={false}
                                    label={(props) => renderCustomizedLabel(props, rateData?.keyword, 'keyword')}
                                    fill="#8884d8"
                                    paddingAngle={1}
                                    dataKey="value"
                                    isAnimationActive={false}
                                >
                                    <Label value='키워드' position='center' fill='#444' />
                                    {keywordPieData.data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={keywordPieData.colors[index % keywordPieData.colors.length]} />
                                    ))}
                                </Pie>
                            </>
                        }
                    </PieChart> :
                    <div className={'flex flexcol f-center'}>
                        <div className='spacing-56' />
                        <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                        <div className='spacing-4' />
                        <p className='h7 fc-gray-500'>No Data</p>
                        <p className='h7 fc-gray-500'>데이터가 없어요</p>
                        <div className='spacing-56' />
                    </div>
                }
            </div>
            <div className={anlStyle.Analytics__TopChart__InfoBox}>
                <img src='/img/analytics/analytics-info.png' width={24} height={24} />
                <div className='spacing-16' />
                <p className='h7'>엔티티 안의 데이터 중 하나를 클릭하면 엔티티와 관련된 키워드가 보여지게 됩니다.</p>
            </div>
        </>
    )
}