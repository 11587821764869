import { useEffect, useState } from 'react';
import msgStyle from '../../style/messages.module.css';
import unitStyle from '../../style/units.module.css';
import { initFilter, initSegmentFilterData, listBodyInitObj, segmentFilterObj } from '../../data/segmentData';
import { getFormattedDate } from '../../apis/api/utils';
import { Calendar } from '../Units/Calendar';
import { getRate } from '../../apis/api/analytics/kpis';
import { convertFilterOption } from '../../apis/api/analytics/utils';
import { postSegmentFilters } from '../../apis/api/segment/kpis';

const FilterInput = (props) => {
    const { idx, inputIdx, updateFilterInput, setIsFinish, segmentFilter } = props;
    const [openCondition, setOpenCondition] = useState(false);
    const [openConditionFilter, setOpenConditionFilter] = useState(false);
    const [addFilter, setAddFilter] = useState(false);
    const [isValidate, setIsValidate] = useState(false);
    const [conditionArr, setConditionArr] = useState(['포함', '']);
    const [selectedFilter, setSelectedFilter] = useState();
    const [filterOption, setFilterOption] = useState();
    const userId = sessionStorage.getItem('user');

    const handleSengmentFilter = (e) => {
        const optionVal = e.target.value;
        setSelectedFilter(optionVal);
        if (optionVal.length > 0) setOpenCondition(true);
        else { setOpenCondition(false) }
    }

    const clearSelect = () => {
        const dropdown = document.getElementsByClassName('filter-dropdown')[idx];
        dropdown.value = '';
        setConditionArr(['포함', '']);
        setIsFinish(false);
    }

    const validateFill = (e) => {
        if (!openCondition) return;
        if (e.target.value.length === 0) return;
        setIsValidate(true);
    }

    const updateConditionArr = (idx, newVal) => {
        let copy = [...conditionArr];
        copy[idx] = newVal
        setConditionArr(copy);
    }

    useEffect(() => {
        getRate(userId)
            .then(res => convertFilterOption(res))
    }, [])

    return (
        <div className={msgStyle.Messages__FilterWrap__BottomFilter}>
            <div className={msgStyle.Messages__FilterWrap__Input}>
                <div className={msgStyle.Messages__FilterWrap__Input__flex}>
                    <div className={msgStyle.Messages__FilterWrap__SelectWrap}>
                        <select
                            className={`${msgStyle.Messages__FilterWrap__Select} filter-dropdown pointer`}
                            onChange={(e) => {
                                handleSengmentFilter(e);
                                segmentFilter.filters[idx][inputIdx].type = +e.currentTarget[e.currentTarget.selectedIndex].dataset.typeidx;
                                sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                            }}
                        >
                            <option selected value=''>새 필터 추가하기</option>
                            {
                                Object.keys(segmentFilterObj).map((key, typeIdx) => {
                                    return <option value={key} data-typeidx={typeIdx}>{key}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='spacing-16' />
                    {
                        openCondition &&
                        <div>
                            {
                                openConditionFilter &&
                                <div className={msgStyle.Messages__Modal__Wrap}>
                                    <p className='h13 fc-gray-600'>조건</p>
                                    <div className='spacing-16' />
                                    <div className={msgStyle.Messages__FilterWrap__Input}>
                                        <select 
                                            className={`${msgStyle.Messages__FilterWrap__Select} filter-dropdown pointer`}
                                            onChange={(e) => {
                                                updateConditionArr(0, e.target.value);
                                                segmentFilter.filters[idx][inputIdx].include = (e.target.value === '포함' ? 1 : 0);
                                                sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                                            }}
                                        >
                                            <option value='포함'>포함</option>
                                            <option value='불포함'>불포함</option>
                                        </select>
                                    </div>
                                    <div className='spacing-16' />
                                    {
                                        selectedFilter === '클릭한 상품' || selectedFilter === '추천받은 상품' ?
                                        <input 
                                            type='text' 
                                            className={msgStyle.Messages__FilterWrap__Input} 
                                            onChange={(e) => {
                                                updateConditionArr(1, e.target.value)
                                                validateFill(e);
                                                segmentFilter.filters[idx][inputIdx].keyword = e.target.value;
                                                sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                                            }}
                                        /> :
                                        <div className={msgStyle.Messages__FilterWrap__Input}>
                                            <select 
                                                className={`${msgStyle.Messages__FilterWrap__Select} filter-dropdown`}
                                                onChange={(e) => {
                                                    updateConditionArr(1, e.target.value)
                                                    validateFill(e);
                                                    segmentFilter.filters[idx][inputIdx].keyword = e.target.value;
                                                    sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                                                }}
                                            >
                                                <option value='' selected disabled>선택</option>
                                                {
                                                    segmentFilterObj[selectedFilter].map((val, idx) => {
                                                        return <option value={val}>{val}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                    <div className='spacing-16' />
                                    <hr className={msgStyle.Messages__BreakLine} />
                                    <div className='spacing-16' />
                                    <div className={unitStyle.Flex__HRight}>
                                        <div
                                            className={unitStyle.Button__cancel}
                                            style={{ width: '92px' }}
                                            onClick={() => {
                                                setConditionArr(['포함', ''])
                                                setOpenConditionFilter(false)
                                                setIsFinish(false)
                                                segmentFilter.filters[idx][inputIdx].include = 1;
                                                segmentFilter.filters[idx][inputIdx].keyword = '';
                                                sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                                            }}
                                        >
                                            <p className='h6 fc-prim-800 pointer'>취소하기</p>
                                        </div>
                                        <div className='spacing-16' />
                                        <div 
                                            className={isValidate ? unitStyle.Button__primary : unitStyle.Button__secondary} 
                                            style={{ width: '92px' }}
                                            onClick={() => {
                                                setOpenConditionFilter(false)
                                                setIsFinish(true)
                                            }}
                                        >
                                            <p className='h6 fc-white pointer'>적용하기</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                conditionArr[1].length > 0 ?
                                <div
                                    key={idx}
                                    className={msgStyle.Messages__FilterWrap__Tag}
                                >
                                    <span className='h7 fc-gray-700'>{`${conditionArr[1]}을(를) ${conditionArr[0]}한 경우`}</span>
                                    <img 
                                        className='pointer'
                                        src='/img/messages/messages-close.png' 
                                        width={18} height={18} 
                                        onClick={() => {
                                            clearSelect()
                                            setIsFinish(false);
                                            segmentFilter.filters[idx][inputIdx].include = 1;
                                            segmentFilter.filters[idx][inputIdx].keyword = '';
                                            sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                                        }}
                                    />
                                </div> :
                                <div
                                    className={msgStyle.Messages__FilterWrap__Tag}
                                    onClick={() => setOpenConditionFilter(true)}
                                >
                                    <img 
                                        className='pointer'
                                        src='/img/messages/messages-add.png' 
                                        width={18} height={18} 
                                        style={{ marginRight: '2px' }} />
                                    <span className='h7 fc-gray-700'>조건 추가</span>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className={msgStyle.Messages__FilterWrap__Input__flex}>
                    {
                        !addFilter &&
                        <div 
                            className={`${msgStyle.Messages__FilterWrap__Operator} pointer`}
                            onClick={() => {
                                updateFilterInput('add');
                                setAddFilter(true);
                                segmentFilter.filters[idx].push(initFilter);
                                sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                            }}
                        >
                            <p className='h7 fc-prim-800'>또는</p>
                        </div>
                    }
                    <div className='spacing-16' />
                    <img 
                        className='pointer'
                        src='/img/messages/messages-cancel.png' 
                        width={24} height={24} 
                        onClick={(e) => {
                            clearSelect(e);
                            segmentFilter.filters[idx][inputIdx].include = 1;
                            segmentFilter.filters[idx][inputIdx].keyword = '';
                            sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                        }}
                    />
                </div>
            </div>
            <div className='spacing-16' />
            {
                addFilter &&
                <>
                    <div className='spacing-16' />
                    <div className={unitStyle.Flex__HCenter}>
                        <hr className={msgStyle.Messages__BreakLine} />
                        <div className={msgStyle.Messages__FilterWrap__Operator__clicked}>
                            <p className='h7 fc-gray-700'>또는</p>
                            <img 
                                className='pointer'
                                src='/img/messages/messages-close.png' 
                                width={18} height={18} 
                                onClick={() => {
                                    updateFilterInput('delete');
                                    setAddFilter(false);
                                    segmentFilter.filters[idx].splice(inputIdx, 1);
                                    sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                                }}
                            />
                        </div>
                        <hr className={msgStyle.Messages__BreakLine} />
                    </div>
                </>
            }
        </div>
    )
}

const FilterWrap = (props) => {
    const {idx, updateFilterSet, setIsFinish, segmentFilter} = props;
    const [filterInputArr, setFilterInputArr] = useState([true]);
    const [addFilter, setAddFilter] = useState(false);

    const updateFilterInput = (type) => {
        let copy = [...filterInputArr];
        if (type === 'add') copy.push(true);
        if (type === 'delete') copy.pop();
        setFilterInputArr(copy);
    }

    return (
        <>
            <div className='spacing-16' />
            <div className={msgStyle.Messages__FilterWrap__Box}>
                <div className={msgStyle.Messages__FilterWrap__Box__left}>A</div>
                <div className={msgStyle.Messages__FilterWrap__Box__right}>
                    <hr className={msgStyle.Messages__BreakLine} />
                    <div className='spacing-16' />
                    {
                        filterInputArr.map((input, inputIdx) => {
                            return (
                                <FilterInput 
                                    key={inputIdx} 
                                    idx={idx}
                                    inputIdx={inputIdx} 
                                    updateFilterInput={updateFilterInput}
                                    setIsFinish={setIsFinish}
                                    segmentFilter={segmentFilter}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className='spacing-16' />
            {
                addFilter ?
                <div 
                    className={msgStyle.Messages__FilterWrap__Operator__clicked}
                >
                    <p className='h7 fc-gray-700'>그리고</p>
                    <img 
                        className='pointer'
                        src='/img/messages/messages-close.png' 
                        width={18} height={18} 
                        onClick={(e) => {
                            e.stopPropagation();
                            updateFilterSet('delete');
                            setAddFilter(false);
                            segmentFilter.filters.splice(idx, 1);
                            sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                        }}
                    />
                </div> :
                <div 
                    className={`${msgStyle.Messages__FilterWrap__Operator} pointer`}
                    onClick={(e) => {
                        e.stopPropagation();
                        updateFilterSet('add');
                        setAddFilter(true);
                        segmentFilter.filters.push([initFilter]);
                        sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                    }}
                >
                    <p className='h7 fc-prim-800'>그리고</p>
                </div>
                
            }
        </>
    )
}

const SegmentSet = (props) => {
    const { navigateTab, updateBodyList, lastId, setIsEditorOpen } = props;
    const [filterSetArr, setFilterSetArr] = useState([true]);
    const [isFinish, setIsFinish] = useState(false);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const segmentFilter = JSON.parse(sessionStorage.getItem('segmentFilter'));
    const userId = sessionStorage.getItem('user');

    const updateFilterSet = (type) => {
        let copy = [...filterSetArr];
        if (type === 'add') copy.push(true);
        if (type === 'delete') copy.pop();
        setFilterSetArr(copy);
    }

    let today = new Date();
    
    const formatDate = (m, d, y) => {
        return `${m+1}월 ${d}일, ${y}`;
    }
    
    let formattedToday = formatDate(today.getMonth(), today.getDate(), today.getFullYear());
    
    const [displayedDate, setDisplayedDate] = useState(formattedToday);

    const updateDateRange = (update) => {
        setDateRange(update);
    }

    return (
        <div className={msgStyle.Messages__Contents}>
            <p className="h4 fc-gray-600">세그먼트 설정</p>
            <div className='spacing-24' />
            <div>
                <p className="h6 fc-gray-600">세그먼트 이름</p>
                <div className='spacing-8' />
                <input
                    type="text"
                    className={msgStyle.Messages__SegmentSet__NameInput}
                    placeholder='세그먼트 이름'
                    onChange={(e) => {
                        updateBodyList(lastId, 'name', e.target.value);
                        segmentFilter.segName = e.target.value;
                        sessionStorage.setItem('segmentFilter', JSON.stringify(segmentFilter));
                    }}
                />
            </div>
            <div className='spacing-32' />
            <hr className={msgStyle.Messages__BreakLine} />
            <div className='spacing-32' />
            <p className="h6 fc-gray-600">기간 설정</p>
            <div className='spacing-8' />
            <Calendar 
                type="segment" 
                displayedDate={displayedDate}
                setDisplayedDate={setDisplayedDate}
                updateDateRange={updateDateRange}
            />
            <div className='spacing-32' />
            <p className="h6 fc-gray-600">필터</p>
            {
                filterSetArr.map((set, idx) => {
                    return <FilterWrap key={idx} idx={idx} updateFilterSet={updateFilterSet} setIsFinish={setIsFinish} segmentFilter={segmentFilter}/>
                })
            }
            <div className='spacing-32' />
            <div className={unitStyle.Flex__HRight}>
                <div 
                    className={`${(isFinish && segmentFilter.segName.length > 0) ? unitStyle.Button__primary : unitStyle.Button__secondary} pointer`} 
                    style={{ width: '75px', height: '50px' }}
                    onClick={() => {
                        if (segmentFilter.segName.length === 0) return;
                        const newDate = getFormattedDate(new Date(), 'full');
                        updateBodyList(lastId, 'createdDate', newDate);
                        setIsEditorOpen(false);
                        navigateTab(0);
                        postSegmentFilters(segmentFilter.segName, userId, segmentFilter.filters, '2024-04-01', '2024-04-29');
                    }}
                >
                    <p className='h4 fc-white'>완료</p>
                </div>
            </div>
        </div>
    )
}

export default SegmentSet