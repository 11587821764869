import * as XLSX from 'xlsx';
import { keyMap } from '../../../data/template.js';
import { convertImg, upsertXLSX } from './api.js';
import { useDispatch } from 'react-redux';
import { GET_INIT_EXAM_DATA } from '../../../redux/test/testTypes.js';

export const exportXLSX = (data) => {
    const fileName = `template.xlsx`;
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data, {origin: 'A4'});

    XLSX.utils.sheet_add_aoa(worksheet, [['아래의 예시와 같이 데이터를 입력해주세요. PID, 상품 가격(원), 평점은 꼭 숫자만 입력해 주세요!']], {origin: 'A1'});
    XLSX.utils.sheet_add_aoa(worksheet, [['원하는 항목의 데이터를 아래 [추가 항목]의 값으로 입력하실 수 있습니다. (선택 사항)']], {origin: 'A2'});
    XLSX.utils.sheet_add_aoa(worksheet, [['↓↓ 원하는 이름으로 바꾸고, 값을 추가해보세요!']], {origin: 'J3'});
    XLSX.utils.book_append_sheet(workbook, worksheet, 'template sheet');

    XLSX.writeFile(workbook, fileName);
};

export const convertKey = (data) => {
    const newArr = [];
    
    data.map((arr, idx) => {
        if (idx === 0) return;
        const newObj = {};
        arr.map((item, idx) => {
            if (keyMap[data[0][idx]]) {
                newObj[keyMap[data[0][idx]]] = item;
            } else {
                newObj[data[0][idx]] = item;
            }
        })
        newArr.push(newObj);
    })

    return newArr;
}

export const convertEmail = (email) => {
    const regex = /^([^@]+)@([^.]+)(?:\.\w+)+$/;
    const match = email.match(regex);

    if (match) {
        const id = match[1];
        const domainWithoutTld = match[2];
        console.log('matched email', id, domainWithoutTld);
        return `${id}_${domainWithoutTld}`;
    } else {
        return email
    }
}

export const readXLSX = (file, email, setCheckState, setUploadedState, setIsCreating, dispatch) => {
    console.log('readXLSX func called', file);
    const reader = new FileReader();

    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {type: 'binary'});

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const res = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const truncatedRes = res.slice(3, 34);
        let convertedRes = convertKey(truncatedRes);
        console.log('convertedRes', convertedRes);
        convertedRes = JSON.stringify(convertedRes);

        const blob = new Blob([convertedRes], { type: 'application/json' });

        var formData = new FormData();

        // JSON 객체를 문자열로 변환하여 FormData에 추가
        formData.append('file', blob, 'data.json');
        formData.append('namespace', convertEmail(email));

        // 파일을 FormData에 추가 (fileInput은 파일을 선택하는 input 엘리먼트여야 함)
        // var fileInput = document.getElementById('myfile');
        // formData.append('file', fileInput.files[0]);

        upsertXLSX(formData, setCheckState)
            .then(
                (res) => {
                    console.log('upsert xlsx, ', res);
                    setUploadedState(res?.status);
                    localStorage.setItem('example', JSON.stringify(res?.example));
                    setIsCreating(false);
                    dispatch({ type: GET_INIT_EXAM_DATA, payload: { example: res?.example }});
                });
    }

    reader.readAsBinaryString(file);
}

export const importXLSX = (uploaded, email, setCheckState, setUploadedState, setIsCreating, dispatch) => {
    console.log('importXLSX func called', uploaded);
    if (uploaded) {
        setIsCreating(true);
        readXLSX(uploaded, email, setCheckState, setUploadedState, setIsCreating, dispatch);
    }
}

export const uploadImg = (file, updateSetting) => {
    console.log('uploadImg func called');
    const formData = new FormData();
    formData.append('file', file);
    

    convertImg(formData)
        .then((res) => updateSetting('logoInput', res));
}