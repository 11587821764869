import { configureStore } from "@reduxjs/toolkit";
import testReducer from "./test/testReducer.js";


const store = configureStore({
    reducer: {
        test: testReducer,
    }
})

export default store