import agentStyle from '../../style/agent.module.css';

export const TableLabel = (props) => {
    const {status} = props;
    const wrapStyle = agentStyle.AgentList__Table__ActiveLabel;
    const fontStyle = 'fc-positive-900';
    const text = '활성화'

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div className={agentStyle.AgentList__Table__ActiveLabel}>
                <p className='h7 fc-positive-900'>{text}</p>
            </div>
        </div>
    )

}
