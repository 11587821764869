import axios from "axios";
import { getFormattedDate } from "./utils.js";

export const getInsight = async (date, userId) => {
    let startDate = getFormattedDate(date);
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate = getFormattedDate(endDate);
    try {
        const response = await axios.get(process.env.REACT_APP_GET_INSIGHT_URL, {
            params: {
                "userId": userId,
                "from": startDate,
                "to": endDate,
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error while calling insight API: ${error.message}`);
        throw error;
    }
}

export const getWAU = async (date, userId) => {
    let toDate = new Date(date);
    toDate.setDate(toDate.getDate() + 6);
    toDate = getFormattedDate(toDate);
    try {
        const response = await axios.get(process.env.REACT_APP_GET_WAU_URL, {
            params: {
                randomId: userId,
                to: toDate,
            },
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.data
    } catch (error) {
        console.error(`Error while calling wau API: ${error.message}`);
        throw error;
    }
}

export const getCardData = async (date, userId) => {
    let startDate = date;
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate = getFormattedDate(endDate);

    try {
        const response1 = await axios.get(process.env.REACT_APP_GET_ERROR_RATE_URL, {
            params: {
                from: startDate,
                to: endDate,
                randomId: userId,
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });

        const response2 = await axios.get(process.env.REACT_APP_GET_INPRESSION_URL, {
            params: {
                from: startDate,
                to: endDate,
                randomId: userId,
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });

        return {
            errorRate: response1.data.rate,
            impression: response2.data.rate,
        };
    } catch (error) {
        console.error(`Error while calling error API: ${error.message}`);
        throw error;
    }
}

export const getFeed = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_GET_FEED_URL, {
            params: {
                userId: 'dlst',
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getFeed API: ${error.message}`);
        throw error;
    }
}

export const getFeedContent = async (feedId, type, text) => {
    try {
        const response = await axios.get(process.env.REACT_APP_GET_FEED_CONTENT_URL, {
            params: {
                userId: 'dlst',
                feedId: feedId,
                type: type,
                text: text,
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getFeed API: ${error.message}`);
        throw error;
    }
}

export const getAnalytics = async (date) => {
    let startDate = date;
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate = getFormattedDate(endDate);
    startDate = getFormattedDate(startDate);

    try {
        const response = await axios.get(process.env.REACT_APP_GET_ANALYTICS_URL, {
            params: {
                "userId": 'dlst',
                "from": startDate,
                "to": endDate,
            },
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data.data
    } catch (error) {
        console.error(`Error while calling wau API: ${error.message}`);
        throw error;
    }
}

export const postPushMessages = async (data) => {
    try {
        const response = await axios.post(process.env.REACT_APP_GET_MESSAGE_URL, {
            "userId": "dlst",
            "feedId": data.feedId,
            "type": data.type,
            "text": data.text, 

            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.data.statusCode === 200) { return true }
        else { return false }
    } catch (error) {
        console.error(`Error while calling push msg API: ${error.message}`);
        throw error;
    }
}

export const getPushMessages = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_GET_MESSAGE_URL, {
            params: {
                "userId": "dlst",
            },
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.data.data;
    } catch (error) {
        console.error(`Error while calling push msg API: ${error.message}`);
        throw error;
    }
}

export const postMsgLike = async (data) => {
    try {
        const response = await axios.post(process.env.REACT_APP_POST_MESSAGE_LIKE_URL, {
            'userId': 'dlst',
            'messageId': data.messageId,
            'title': data.title,
            'content': data.content,

            headers: {
                "Content-Type": "application/json"
            }
        });

        console.log('postMsgLike on success');
    } catch (error) {
        console.error(`Error while calling post msg like API: ${error.message}`);
        throw error;
    }
}
