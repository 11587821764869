import agentStyle from '../../style/agent.module.css';

export const BotStat = () => {
    return (
        <div className={agentStyle.AgentList__Container}>
            <div className={agentStyle.AgentList__Contents}>
                <span className='h4'>준비 중인 기능입니다</span>
                <img src='/img/units/gentoo-symbol.png' width={24} height={24} />
            </div>
        </div>
    )
}