import { useEffect, useState } from 'react';
import { bookmarkList, keywordCardData, relativeKeywordList } from '../../data/analyticsData';
import anlStyle from '../../style/analytics.module.css';
import { getBookmark, getBookmarkKeyword, postBookmark, searchProduct } from '../../apis/api/analytics/kpis';

export const ProdBookmark = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bookmarkList, setBookmarkList] = useState();
    const [keywordList, setKeywordList] = useState([]);
    const [isBookmarkUpdated, setIsBookmarkUpdated] = useState(false);
    const userId = sessionStorage.getItem('user');

    useEffect(() => {
        getBookmark(userId)
            .then(res => setBookmarkList(res))
    }, [isModalOpen, isBookmarkUpdated])

    return (
        <>
            <p className='h4 fc-gray-700'>🎯 핵심 상품 관리</p>
            <div className='spacing-4' />
            <p className='h7 fc-gray-600'>쇼핑몰에서 주력하는 상품을 직접 추가하고 데이터를 통해 상품 성과 및 트렌드를 분석하여 전략적 마케팅을 수립할 수 있습니다.</p>
            <div className='spacing-16' />
            <hr className={anlStyle.Analytics__BreakLine} />
            <div className={anlStyle.ProdBookmark__ListContainer}>
                <div className={anlStyle.ProdBookmark__ListBox__left}>
                    <div className='spacing-48' />
                    <div className='flex f-between'>
                        <p className='h4 fc-gray-700'>핵심 상품 리스트</p>
                        {
                            bookmarkList?.length > 0 &&
                            <button 
                                className={`${anlStyle.ProdBookmark__Button__Add} flex f-center`}
                                onClick={() => setIsModalOpen(true)}
                            >
                                <img src='/img/analytics/bookmark-addblue.png' width={18} height={18} />
                                <div className='spacing-4' />
                                <p className='h6 fc-prim-800'>추가하기</p>
                            </button>
                        }
                    </div>
                    <div className='spacing-24' />
                    <div className={anlStyle.Analytics__Table__Wrap}>
                        <table className={anlStyle.Analytics__Table}>
                            <thead>
                                <tr>
                                    <th className={anlStyle.Analytics__Table__Head__gray} />
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'> </p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>상품명</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>노출수</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>클릭수</p>
                                        </th>
                                    <th className={anlStyle.Analytics__Table__Head__gray} />
                                </tr>
                            </thead>
                            <tbody>
                                    {
                                        bookmarkList?.map((td, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <img 
                                                            src='/img/analytics/bookmark-fill.png' 
                                                            width={24} height={24} 
                                                            className='pointer' 
                                                            onClick={() => {
                                                                postBookmark(userId, td.PID, 0)
                                                                    .then(() => setIsBookmarkUpdated(!isBookmarkUpdated));
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p 
                                                            className='h7 pointer'
                                                            onClick={() => {
                                                                getBookmarkKeyword(userId, td.PID)
                                                                    .then(res => setKeywordList(res))
                                                            }}
                                                        >{td.name}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.exposedNum}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.clickedNum}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                </tr>
                                            )
                                        })
                                    }
                            </tbody>
                        </table>
                        {
                            bookmarkList?.length === 0 &&
                            <div className={'flex flexcol f-center'}>
                                <div className='spacing-56' />
                                <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                                <div className='spacing-4' />
                                <p className='h7 fc-gray-500'>No Data</p>
                                <p className='h7 fc-gray-500'>핵심 상품 리스트가 비었어요</p>
                                <div className='spacing-16' />
                                <button 
                                    className={`${anlStyle.ProdBookmark__Button__Add} flex f-center`}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    <img src='/img/analytics/bookmark-addblue.png' width={18} height={18} />
                                    <div className='spacing-4' />
                                    <p className='h6 fc-prim-800'>추가하기</p>
                                </button>
                                <div className='spacing-56' />
                            </div>
                        }
                    </div>
                </div>
                <div className={anlStyle.ProdBookmark__ListBox__right}>
                    <div className='spacing-48' />
                    <p className='h4 fc-gray-700'>상품 관련 키워드</p>
                    <div className='spacing-24' />
                    <div className={'flex'}>
                        <div className={anlStyle.Analytics__Table__Wrap}>
                            <table className={anlStyle.Analytics__Table}>
                                <thead>
                                    <tr>
                                        <th className={anlStyle.Analytics__Table__Head__gray} />
                                        {
                                            relativeKeywordList.head.map((th, idx) => {
                                                return (
                                                    <th key={idx} className={anlStyle.Analytics__Table__Head__gray}>
                                                        <p className='h6'>{th}</p>
                                                    </th>
                                                )
                                            })
                                        }
                                        <th className={anlStyle.Analytics__Table__Head__gray} />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        keywordList?.map((td, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.rank}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.rawKeyword}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.exposedNum}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.clickedNum}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>
                            {
                                keywordList?.length === 0 &&
                                <div className={'flex flexcol f-center'}>
                                    <div className='spacing-56' />
                                    <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                                    <div className='spacing-4' />
                                    <p className='h7 fc-gray-500'>No Data</p>
                                    <p className='h7 fc-gray-500'>좌측의 상품을 클릭해보세요</p>
                                    <div className='spacing-56' />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                isModalOpen ?
                <div className={anlStyle.Analytics__Dimmed}>
                    <SearchModal setIsModalOpen={setIsModalOpen}/>
                </div> :
                null
            }
        </>
    )
}

export const SearchModal = (props) => {
    const {setIsModalOpen} = props;
    const [input, setInput] = useState('');
    const [displayedName, setDisplayedName] = useState('');
    const [products, setProducts] = useState();
    const userId = sessionStorage.getItem('user');

    const getSearchedProduct = async () => {
        const res = await searchProduct(userId, input);
        setProducts(res);
        setDisplayedName(input);
    }

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            getSearchedProduct();
        }
    };

    return (
        <div className={anlStyle.ProdBookmark__SearchModal__Wrap}>
            <div className={anlStyle.ProdBookmark__SearchModal__Top}>
                <p className='h4 fc-gray-700'>핵심 상품 추가하기</p>
                <img 
                    src='/img/units/toast-close.png' width={48} height={48} 
                    onClick={() => setIsModalOpen(false)}
                    className='pointer'
                />
            </div>
            <div className='spacing-48' />
            <div className={anlStyle.ProdBookmark__SearchModal__SearchWrap}>
                <p className='h4 fc-gray-700'>상품 검색</p>
                <div className='spacing-16' />
                <div className={anlStyle.ProdBookmark__SearchModal__InputWrap}>
                    <input 
                        className={anlStyle.ProdBookmark__SearchModal__Input} 
                        placeholder='상품 ID나 상품명으로 검색해 보세요'
                        onChange={(e) => {setInput(e.target.value)}}
                        onKeyUp={handleKeyPress}
                    />
                    <img 
                        src='/img/analytics/bookmark-search.png' 
                        width={24} height={24} 
                        className='pointer'
                        onClick={() => getSearchedProduct()}
                    />
                </div>
            </div>
            {
                products && products.length > 0 ?
                <>
                    <div className='spacing-32' />
                    <div className={anlStyle.ProdBookmark__SearchModal__ListWrap}>
                        <p className='h4 fc-gray-600'>{displayedName} 검색 결과 {products.length}개</p>
                        <div className='spacing-24' />
                        <div className={anlStyle.Analytics__Table__Wrap}>
                            <table className={anlStyle.Analytics__Table}>
                                <thead>
                                    <tr>
                                        <th className={anlStyle.Analytics__Table__Head__gray} />
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'> </p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>상품ID</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>상품명</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>노출수</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>클릭수</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray} />
                                    </tr>
                                </thead>
                                <tbody>
                                        {
                                            products.map((td, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td className={anlStyle.Analytics__Table__Body__underline} />
                                                        <td className={anlStyle.Analytics__Table__Body__underline}>
                                                            <img 
                                                                src={td.bookmark ? '/img/analytics/bookmark-fill.png' : '/img/analytics/bookmark-add.png'} 
                                                                width={24} height={24} 
                                                                className='pointer' 
                                                                onClick={() => {
                                                                    postBookmark(userId, td.PID, !td.bookmark)
                                                                        .then(() => {
                                                                            let copy = [...products];
                                                                            copy[idx].bookmark = !td.bookmark;
                                                                            setProducts(copy);
                                                                        })
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={anlStyle.Analytics__Table__Body__underline}>
                                                            <p className='h7'>{td.PID}</p>
                                                        </td>
                                                        <td className={anlStyle.Analytics__Table__Body__underline}>
                                                            <p className='h7'>{td.name}</p>
                                                        </td>
                                                        <td className={anlStyle.Analytics__Table__Body__underline}>
                                                            <p className='h7'>{td.exposedNum}</p>
                                                        </td>
                                                        <td className={anlStyle.Analytics__Table__Body__underline}>
                                                            <p className='h7'>{td.clickedNum}</p>
                                                        </td>
                                                        <td className={anlStyle.Analytics__Table__Body__underline} />
                                                    </tr>
                                                )
                                            })
                                        }
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </> :
                <>
                    <div className='spacing-80' />
                    <div className='flex flexcol f-center' style={{height: 'fit-content'}}>
                        <img src='/img/analytics/bookmark-search.png' width={48} height={48} />
                        <div className='spacing-24' />
                        <p className='h4 fc-gray-700'>최대 10개까지 핵심 상품을 추가해보세요</p>
                        <div className='spacing-16' />
                        <p className='h7 fc-gray-600'>상품 ID, 상품명으로 검색해보세요</p>
                        <p className='h7 fc-gray-600'>(키워드가 정확히 일치하는 것만 찾을 수 있습니다)</p>
                    </div>
                </>
            }
        </div>
    )
}