import anlStyle from '../../style/analytics.module.css';
import { KeywordList, KeywordModal } from './KeywordList';
import { TopCharts } from './TopCharts';
import '../../../node_modules/pikaday/css/pikaday.css';
import { Calendar } from '../Units/Calendar';
import { ProdBookmark } from './ProdBookmark';
import { useEffect, useState } from 'react';


const Analytics = () => {
    let today = new Date();
    
    const formatDate = (m, d, y) => {
        return `${m+1}월 ${d}일, ${y}`;
    }
    
    let formattedToday = formatDate(today.getMonth(), today.getDate(), today.getFullYear());
    
    const [displayedDate, setDisplayedDate] = useState(formattedToday);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;
    const [from, setFrom] = useState(startDate ? `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`: null);
    const [to, setTo] = useState(endDate ? `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}` : null);

    const [displayedRange, setDisplayedRange] = useState(formatDate(startDate?.getMonth(), startDate?.getDate(), startDate?.getFullYear()))

    const updateDateRange = (update) => {
        setDateRange(update);
    }

    useEffect(() => {
        const newDate = `${formatDate(startDate.getMonth(), startDate.getDate(), startDate.getFullYear())} ${endDate ? (' ~ ' + formatDate(endDate.getMonth(), endDate.getDate(), endDate.getFullYear())): ''}`;
        setDisplayedRange(newDate);
        setDisplayedDate(newDate);
        setFrom(`${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`);
        setTo(endDate && `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`);
    } ,[displayedDate, dateRange, startDate, endDate])

    return (
        <div className={anlStyle.Analytics__Container}>
            <div className={anlStyle.Analytics__Header}>
                <div className={anlStyle.Analytics__Header__Tab__selected}>
                    <p className='h6 fc-prim-800'>젠투 에이전트 분석</p>
                </div>
                <div className={anlStyle.Analytics__Header__Blank} />
                <Calendar 
                    type="analytics"
                    displayedDate={displayedDate}
                    setDisplayedDate={setDisplayedDate}
                    displayedRange={displayedRange}
                    setDisplayedRange={setDisplayedRange}
                    startDate={startDate}
                    endDate={endDate}
                    dateRange={dateRange}
                    updateDateRange={updateDateRange}
                />
            </div>
            <div className='spacing-32' />
            <div className={anlStyle.Analytics__Contents}>
                <TopCharts
                    from={from}
                    to={to}
                />
            </div>
            <div className='spacing-32' />
            <div className={anlStyle.Analytics__Contents}>
                <ProdBookmark />
            </div>
            <div className='spacing-32' />
            <div className={anlStyle.Analytics__Contents}>
                <KeywordList 
                    from={from}
                    to={to}
                />
            </div>
        </div>
    )
}

export default Analytics