import { forwardRef, useState } from "react";
import { FileDownload, FileUpload } from "../components/Units/FileTransfer"
import Toast from "../components/Units/Toast"
import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import '../style/datePicker.css';

registerLocale('ko', ko)

const CompTest = () => {
    const [state, setState] = useState(false);

    const sendPostMessages = () => {
        const targetWindow = window.parent;
        const testData = {
            name: 'test',
            contents: 'yayaya',
        }
        targetWindow.postMessage(testData, '*');
    }

    return (
        <div style={{width: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <button onClick={() => sendPostMessages()}>send message</button>
            </div>
        </div>
    )
}

export default CompTest