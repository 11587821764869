import styled from 'styled-components';
import { colorSet, colors } from '../style/colorVar';
import { TopNav } from '../components/Main/TopNav';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Login from './Login';
import GlobalStyle from '../style/GlobalStyle';

const MainDLST = () => {
    const navigate = useNavigate();
    let location = window.location.pathname;
    const [isLogin, setIsLogin] = useState(sessionStorage.getItem('login'));
    const [tryLogout, setTryLogout] = useState(false);
    let [clientWidth, setClientWidth] = useState(0);
    const username = sessionStorage.getItem('login');
    
    useEffect(() => {
        setClientWidth(document.getElementById('main-layout').clientWidth);
        // console.log(clientWidth);   
    },[])

    useEffect(() => {
        console.log('location updated');
    }, [location]);

    const handleNavigate = (e) => {
        // e.preventDefault();
        switch (e.currentTarget.id) {
            case "menu1":
                navigate('/dlst');
                break;
            case "menu2":
                navigate('/dlst/insight');
                break;
            case "menu3":
                navigate('/dlst/analytics');
                break;
            case "menu4":
                navigate('/dlst/messages');
                break;
            case "menu5":
                navigate('/dlst/usage');
                break;
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        const target = e.currentTarget.id;

        if (target === 'menu6') {
            setTryLogout(true);   
        } else if (target === 'logout-success') {
            setTryLogout(false);
            sessionStorage.removeItem('login');
            setIsLogin(sessionStorage.getItem('login'));
        } else if (target === 'logout-fail') {
            setTryLogout(false);
        }
    }

    return (
        <>
            <GlobalStyle />
            <Background id="main-layout" style={{overflowY: "scroll"}} $backgroundColor={(isLogin ? null : colorSet.scnd50)}>
                {/* <TopNav /> */}
                {/* <Buffer /> */}

                {
                    !isLogin ? 
                    <Login isLogin={isLogin} setIsLogin={setIsLogin}/> :
                    <GridWrap $gridCol="260px 1fr">
                        <SideMenu>
                            <MenuGroup>
                                <FlexWrap $flex="0 0 162px">
                                    <Logo />
                                </FlexWrap>
                                <MenuWrap $flex="1 0 max-content">
                                    <FlexRow>
                                        <Menu id="menu1" type='radio' name='menu' onClick={(e) => {handleNavigate(e)}} defaultChecked={location === '/dlst'}/>
                                        <MenuLabel htmlFor="menu1"><MenuIcons /> Dashboard</MenuLabel>    
                                    </FlexRow>
                                    <FlexRow>
                                        <Menu id="menu2" type='radio' name='menu' onClick={(e) => {handleNavigate(e)}} defaultChecked={location === '/dlst/insight'}/>
                                        <MenuLabel htmlFor="menu2"><MenuIcons $background="url(/img/menu/insight_FILL0_wght300_GRAD0_opsz24.svg)" /> Insight</MenuLabel>    
                                    </FlexRow>
                                    <FlexRow>
                                        <Menu id="menu3" type='radio' name='menu' onClick={(e) => {handleNavigate(e)}} defaultChecked={location === '/dlst/analytics'}/>
                                        <MenuLabel htmlFor="menu3"><MenuIcons $background="url(/img/menu/analytics_FILL0_wght300_GRAD0_opsz24.svg)" /> Analytics</MenuLabel>    
                                    </FlexRow>
                                    <FlexRow>
                                        <Menu id="menu4" type='radio' name='menu' onClick={(e) => {handleNavigate(e)}} defaultChecked={location === '/dlst/messages'}/>
                                        <MenuLabel htmlFor="menu4"><MenuIcons $background="url(/img/menu/msgCCCCCC.svg)" /> Messages</MenuLabel>    
                                    </FlexRow>
                                </MenuWrap>
                                <FlexWrap $flex="0 0 auto">
                                    <FlexRow>
                                        <Menu id="menu5" type='radio' name='menu' onClick={(e) => {handleNavigate(e)}} defaultChecked={location === '/dlst/usage'}/>
                                        <MenuLabel htmlFor="menu5"><MenuIcons $background="url(/img/menu/plans_FILL0_wght300_GRAD0_opsz24.svg)" /> Plans</MenuLabel>    
                                    </FlexRow>
                                    <FlexRow>
                                        <Menu id="menu6" type='radio' name='menu' onClick={(e) => {handleLogout(e)}}/>
                                        <MenuLabel htmlFor="menu6"><MenuIcons $background="url(/img/menu/logout_FILL0_wght300_GRAD0_opsz24.svg)" /> Logout</MenuLabel>    
                                    </FlexRow>
                                </FlexWrap>
                            </MenuGroup>
                        </SideMenu>
                        {
                            tryLogout ?
                            <LogoutModal>
                                <span onClick={() => {setTryLogout(false)}}>x</span>
                                <p>로그아웃 하시겠습니까?</p>
                                <button id="logout-fail" onClick={(e) => {handleLogout(e)}}>취소</button>
                                <button id="logout-success" onClick={(e) => {handleLogout(e)}} style={{backgroundColor: colors.pointBlue, color: colors.base}}>로그아웃</button>
                            </LogoutModal> :
                            null
                        }
                        <div style={{width: "260px", height: "100vh"}}></div>
                        <OutletWrap>
                            <Outlet />
                        </OutletWrap>
                    </GridWrap>
                }
            </Background>
        </>
    )
};

const Background = styled.div`
    width: 100%;
    height: 100vh;
    margin: auto;
    background-color: ${props => props.$backgroundColor || colorSet.scnd300};
`

const Buffer = styled.div`
    width: 100%;
    height: 60px;
`

const GridWrap = styled.div`
    display: grid;
    grid-template-columns: ${props => props.$gridCol || ""};
    grid-template-rows: ${props => props.$gridRow || ""};
    grid-column-start: ${props => props.$gridColStart || ""};
    grid-column-end: ${props => props.$gridColEnd || ""};
    // width: 100%;
    // height: calc(100vh - 60px);
    height: 100%;
    // display: flex;
    // flex-direction: row;
`

const SideMenu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: hidden;
    width: 260px;
    height: 100vh;
    padding: 16px 28px;
    background: ${colorSet.scnd50};
`

const Logo = styled.div`
    width: 200px;
    height: 149px;
    background-image: url("/img/menu/waddle-logo-crop.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

const MenuGroup = styled.div`
    height: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        font-size: 16px;
        font-weight: 600;
        word-spacing: 0.3;
        margin-bottom: 16px;
        margin-left: 8px;
    }
`

const FlexWrap = styled.div`
    width: 100%;
    flex: ${props => props.$flex || "0 0 auto"};
`

const MenuWrap = styled(FlexWrap)`
    padding-top: 32px;
`

const MenuIcons = styled.div`
    width: 30px;
    height: 30px;
    background: ${props => props.$background || "url(/img/menu/menu_overview_w300.svg)"};
    margin-right: 8px;
`

const Menu = styled.input`
    display: flex;
    flex-direction: row;
    height: 40px;
    font-size: 16px;
    font-weight: 800;
    margin: 4px 0;
    align-items: center;

    &[type="radio"] {
        appearance: none;
    }

    &[type=radio]:checked + label {
        background: ${colors.pointBlue};
        color: #fff;
        border: none;
        border-radius: 10px;
    }
`

const MenuLabel = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 16px;
    font-size: 16px;
    color: ${colors.fontGray};
    font-weight: 400;
`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`

const OutletWrap = styled.div`
    width: calc(100vw - 260px);
    // height: calc(100vh - 60px - 16px);
    height: 100%;
    padding: 16px;
    padding-bottom: 0;
`

const LogoutModal = styled.div`
    position: absolute;
    top: 40%;
    left: 40%;
    width: 300px;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 3px ${colors.fontGray};
    z-index: 10;

    span {
        position: relative;
        top: 5%;
        left: 90%;
        cursor: pointer;
    }

    p {
        text-align: center;
        position: relative;
        top: 16%;
    }

    button {
        position: relative;
        top: 22%;
        left: 17%;
        width: 30%;
        margin: 4px;
        background-color: ${colors.backDark};
        border: none;
        padding: 4px 8px;
        border-radius: 5px;
        font-size: 12px;
    }

`


export default MainDLST