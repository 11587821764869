export const segmentListInitData = {
  head: [
      '세그먼트 이름',
      '상태',
      '생성일',
      '수정',
      'CSV'
  ],
  body: []
}

export const listBodyInitObj = {
    id: 0,
    name: '무제',
    status: '활성화',
    createdDate: '2월 21일, 2024년',
    editLink: '',
    csvLink: '',
}

export const segmentFilterObj = {
  '검색한 엔티티': [
    '주종',
    '맛',
    '성향',
    '가격',
    '안주',
    '대상',
    '국가',
    '도수',
  ],
  '검색한 키워드': [
    '와인',
    '위스키',
    '선물',
    '레드와인',
    '맥켈란',
    '달달한',
    '버번',
  ],
  '클릭한 상품': [],
  '추천받은 상품': [],
}


export const initSegmentFilterData = {
  segName: "",
  randomId: "",
  filters: [
      [
          {
              "type": 0,
              "include": 1,
              "keyword": ""
          },
      ],
  ],
  from: "2023-04-01",
  to: "2024-04-29"
}

export const initFilter = {
  "type": 0,
  "include": 1,
  "keyword": ""
}