import axios from "axios";

export const upsertXLSX = async (formData, setCheckState) => {
    console.log('upsertXLSX func called');
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_UPLOAD_DATA_URL, 
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )
        setCheckState([true, true]);
        return {
            status: true,
            example: response.data.data.example,
        };
    } catch (error) {
        alert(`Error while calling upsertXLSX API: ${error.response.data.message}`);
        console.error(`Error while calling upsertXLSX API: ${error.response.data.message}`);
    }
}

export const convertImg = async (formData) => {
    console.log('convertImg func called');
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_CONVERT_IMG_URL,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )
        return response.data.imageUrl;
    } catch (error) {
        console.error(`Error while calling convertImg API: ${error}`);
    }
}