import { useParams } from 'react-router-dom';
import agentStyle from '../../style/agent.module.css';
import unitsStyle from '../../style/units.module.css';
import { TableLabel } from '../Units/Label';
import { useState } from 'react';
import { BotList } from './BotList';
import { BotStat } from './BotStat';

export const AgentList = () => {
    const email = useParams().email;
    const [isTabSelected, setIsTabSelected] = useState(0);

    return (
        <>
            <div className={agentStyle.AgentList__Container}>
                <div className={agentStyle.AgentList__Header}>
                    <div
                        data-idx={0} 
                        className={isTabSelected === 0 ? agentStyle.AgentList__Header__Tab__selected : agentStyle.AgentList__Header__Tab}
                        onClick={() => setIsTabSelected(0)}
                    >
                        <p className={`h6 ${isTabSelected === 0 ? 'fc-prim-800' : 'fc-gray-300'}`}>젠투 에이전트 리스트</p>
                    </div>
                    <div
                        data-idx={1} 
                        className={isTabSelected === 1 ? agentStyle.AgentList__Header__Tab__selected : agentStyle.AgentList__Header__Tab}
                        onClick={() => setIsTabSelected(1)}
                    >
                        <p className={`h6 ${isTabSelected === 1 ? 'fc-prim-800' : 'fc-gray-300'}`}>통계: '젠투'</p>
                    </div>
                    <div className={agentStyle.AgentList__Header__Blank} />
                </div>
                <div className='spacing-32' />
                {
                    isTabSelected === 0 ?
                    <BotList email={email} /> :
                    <BotStat />
                }
            </div>
        </>
    )
}