import axios from "axios";

export const signup = async () => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_SIGNUP,
            {
                email: 's_test@email.com',
                password: '1234',
                company: '',
                job: '',
            },{
                headers: {}
            }
        )
        // response exam
        // "data": {
        //     "message": "User created successfully",
        //     "randomId": "xfSFVHqrCCYcvoma"
        // },
    } catch (error) {
        console.error(`Error while calling signup api: ${error}`)
    }
}

export const login = async (email, password) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_LOGIN,
            {
                email: email,
                password: password
            },{
                headers: {}
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error while calling login api: ${error}`)
    }
}