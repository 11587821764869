import { createGlobalStyle } from "styled-components";
import { colorSet } from "./colorVar";

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
        font-weight: 400;
        font-size: 16px;
        color: ${colorSet.scnd900};
        transform: rotate(0.04deg);
        line-height: 1.5;
    }

    * {
        font-family: "NanumSquareNeo", sans-serif;
    }

    div::-webkit-scrollbar {
        display: none;
      }
      
    div, span, button {
        box-sizing: border-box;
        font-family: "NanumSquareNeo", sans-serif;
        
        -ms-overflow-style: none; /* 인터넷 익스플로러 */
        scrollbar-width: none; /* 파이어폭스 */
    }
    
    button {
    cursor: pointer;
    }
    
    h1, h2, h3, h4, h5, h6, p, a {
        box-sizing: border-box;
        vertical-align: middle;
        margin: 0;
        font-family: "NanumSquareNeo", sans-serif;
        font-weight: 400;
    }
`

export default GlobalStyle