export const keyword10Data = {
    head: [
        '순위',
        '키워드',
        '엔티티',
        '노출수',
        '최다 추천 상품',
        '최다 클릭 상품'
    ],
    body: [
        {
            rank: 1,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 2,
            keyword: '와인',
            entity: '주종',
            exposureNr: 387,
            exposureMost: '코트니벤헴 나파벨리 까베르네소비뇽',
            clickMost: '샹파뉴 샤를르 드 까자노브 브뤼',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 3,
            keyword: '버번 위스키',
            entity: '주종',
            exposureNr: 355,
            exposureMost: '버팔로 트레이스',
            clickMost: '버팔로 트레이스',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 4,
            keyword: '부드러운',
            entity: '맛',
            exposureNr: 323,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 5,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 6,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 7,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 8,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 9,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 10,
            keyword: '위스키',
            entity: '주종',
            exposureNr: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
    ]
}

export const initProductCardData = [
    {
        title: '추천 상품 순위',
        head: [
            '순위',
            '상품명',
        ],
        body: [
            {
                rank: 1,
                name: '에반 윌리엄스 블랙',
                productUrl: 'https://dailyshot.co/m/item/10730',
            },
        ]
    },
    {
        title: '클릭 상품 순위',
        head: [
            '순위',
            '상품명',
        ],
        body: [
            {
                rank: 1,
                name: '에반 윌리엄스 블랙',
                productUrl: 'https://dailyshot.co/m/item/10730',
            },
        ]
    },
]

export const initKeywordCardData = [
    {
        title: '함께 언급된 키워드',
        head: [
            '순위',
            '상품명',
        ],
        body: [
            {
                rank: 1,
                keyword: '에반 윌리엄스 블랙',
            },
        ]
    }
]

export const initKeywordTrendData = [
    {
      "name": "02-15",
      "노출량": 3900,
      "amt": 2400
    },
    {
      "name": "02-16",
      "노출량": 3000,
      "amt": 2210
    },
    {
      "name": "02-17",
      "노출량": 2000,
      "amt": 2290
    },
    {
      "name": "02-18",
      "노출량": 2780,
      "amt": 2000
    },
    {
      "name": "02-19",
      "노출량": 1890,
      "amt": 2181
    },
    {
      "name": "02-20",
      "노출량": 2390,
      "amt": 2500
    },
    {
      "name": "02-21",
      "노출량": 3490,
      "amt": 2100
    }
]

export const bookmarkList = {
    head: [
        " ",
        "상품명",
        "노출수",
        "클릭수",
    ],
    body: [
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
        {
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '에반 윌리엄스 블랙',
            exposure: 150,
            click: 77,
        },
    ]
}

export const relativeKeywordList = {
    head: [
        "순위",
        "상품명",
        "언급수",
        "클릭수",
    ],
    body: []
}