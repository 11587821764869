export const keyMap = {
    'PID': 'itemId',
    '대분류 카테고리': 'majorCategory',
    '소분류 카테고리': 'subCategory',
    '상품명': 'name',
    '상품 가격(원)': 'price',
    '상품 설명': 'description',
    '상품 페이지 링크': 'productUrl',
    '이미지 링크': 'imageUrl',
    '평점': 'rate',
}

export const templateData = [
    {
        'PID': '1',
        '대분류 카테고리': '스킨케어',
        '소분류 카테고리': '선크림',
        '상품명': '에스쁘아 워터 스플래쉬 선크림 60ml',
        '상품 가격(원)': '18000',
        '상품 설명': '물방울이 터지는 생기 촉촉 선크림',
        '상품 페이지 링크': 'https://www.espoir.com/ko/shop/shop_prd_view.do?i_sProductcd=125101364',
        '이미지 링크': 'https://images.espoir.com/UPLOAD/UPLOAD_IMAGE/PRD_TP02_02/202205/IMG1652vcZ859867072.jpg',
        '평점': '5.0',
        '추가 항목1': '',
        '추가 항목2': '',
        '추가 항목3': '',
    }
]